<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
                    
            <form class="w-full max-w-lg">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Feature Name
                  </label>
                  <input autocomplete="off" v-model="feature_creation.name" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-first-name" type="text" placeholder="My Feature Name">
                </div>

                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Is this a standard feature?
                  </label>
                  yes
                  <input type="radio" id="yes" value="true" v-model="feature_creation.is_standard">
                  no
                  <input type="radio" id="no" value="false" v-model="feature_creation.is_standard">

                  <div class="mt-3" v-if="feature_creation.is_standard == 'false'">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Select Themes This Applies to:
                      </label>
                        <div v-bind:key="theme.id" v-for="theme of themes">
                            <input
                              type="checkbox"
                              v-model="feature_creation.selected_themes"
                              :true-value="theme.id"
                              :value="theme.id"
                              :false-value="false"
                              class="checked:bg-blue-500 mr-2"
                            >
                            <label class="text-sm mr-2">{{theme.name}}</label>
                        </div>

                    </div>
                    
                    <div class="w-full mt-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Type
                        </label>
                        <select class="method-select" v-model="feature_creation.type_selection">
                            <option disabled value="">Please select one</option>
                            <option>Range</option>
                            <option>Number</option>
                            <option>Other</option>
                        </select>
                    </div>

                    <div v-if="feature_creation.type_selection == 'Range'" class="mt-3 w-full mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Range
                        </label>
                        <div class="flex flex-wrap -mx-3 mb-3">
                            <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                                <input autocomplete="off" v-model="feature_creation.range.start" class="appearance-none w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-start" type="number" placeholder="Start">
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                                <input autocomplete="off" v-model="feature_creation.range.end" class="appearance-none w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-end" type="number" placeholder="End">
                            </div>
                        </div>
                    </div>

                    <div v-if="feature_creation.type_selection == 'Number'" class="mt-3 w-full mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Number
                        </label>
                        <input autocomplete="off" v-model="feature_creation.number" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-number" type="number" placeholder="put in your number...">
                    </div>

                    <div v-if="feature_creation.type_selection == 'Other'" class="mt-3 w-full mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-other">
                            Other
                        </label>
                        <input autocomplete="off" v-model="feature_creation.other" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-other" type="text" placeholder="put method of evaluation here...">
                    </div>

                    <div class="flex flex-wrap mt-3 -mx-3 mb-6">

                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                Frequency
                            </label>
                            <select class="method-select" v-model="feature_creation.frequency">
                                <option disabled value="">Please select one</option>
                                <option>Snapshot</option>
                                <option>Series</option>
                            </select>
                        </div>

                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                Input Type
                            </label>
                            <select class="method-select" v-model="feature_creation.input_type">
                                <option disabled value="">Please select one</option>
                                <option>Human</option>
                                <option>Machine</option>
                            </select>
                        </div>

                    </div>

                    <div v-if="feature_creation.input_type == 'Machine'" class="mt-3 w-full mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-other">
                            Where might we find this data? Any websites or sources that we need to know about?
                        </label>
                        <input autocomplete="off" v-model="feature_creation.source_recommendation" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-other" type="text" placeholder="put source here please.">
                    </div>


                </div>
              </div>
            </form>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="saveFeature" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500">Save</button>
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500">Close</button>
        </div>

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'tags'],
    components: {
    },
    computed: {
        title () {
            return "Add Feature";
        }
    },
    data() {
        return {
            tagText: "",
            tagMatches: [],
            activeTags: [],
            
            feature_creation: {
                range: {
                    start: 1,
                    end: 100
                },
                frequency: "",
                source_recommendation: "",
                selected_themes: [],
                input_type: "",
                type_selection: "",
                is_standard: true,
                name: "",
                description: "",
                number: 0,
                themes: [],
                other: ""
            },
            themes: []
        }
    },
    watch: {
    },
    mounted () {
        this.getThemes();
    },
    methods: {

        async getThemes() {
            const { data } = await this.$http.get(`/themes/basic`);
            this.themes = data;
        },
        
        async saveFeature() {

            const { data } = await this.$http.post(`/themes/feature/save`, this.feature_creation);
        
            if (data.success) {
                this.$emit('close');
                this.feature_creation = {
                    range: {
                        start: 1,
                        end: 100
                    },
                    frequency: "",
                    input_type: "",
                    type_selection: "",
                    is_standard: true,
                    source_recommendation: "",
                    name: "",
                    description: "",
                    number: 0,
                    themes: [],
                    other: ""
                };

                this.mixpanel.track("Saved Feature", this.feature_creation);

            }

        }
    }
}
</script>

<style>
    .method-select {
        border: #555 solid;
        border-width: 1px;
        padding: 7px;
        border-radius: 3px;
    }


</style>