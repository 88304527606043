<template>

    <div>
        <div class="flex" v-if="event">
            <span class="mr-2"><h4>{{ to_key + 1 }}. </h4></span>
            <div class=""><a target="_blank" :href="event.link">{{event.name}} <small class="">({{event.link}})</small></a></div>
        </div>
        
        <div v-if="event && event.votes" class="">
            <span v-on:click="$emit('vote', event, 'event')" :class="(event.voted) ? 'text-green' : ''" class="cursor-pointer">
                <base-icon style="top: 9px" class="relative" :name="'voteUp'"></base-icon>
            </span>
            <small>
                <span>{{event.votes.length}}</span>
                <span v-if="event.votes.length > 1"> upvotes</span>
                <span v-else> upvote</span> | {{ event.date | moment("from", "now", true) }} ago | <a v-on:click="event.show_comments = !event.show_comments;" class="cursor-pointer">{{event.comments.length}} Comments</a>
            </small>
        </div>
        <div class="basis-2/4 grow flex justify-end">
            <button class="bg-medium-orange font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" v-on:click="$emit('trashEvent', event)">
                <span v-if="event.status != 0" class="text-white font-bold">Archive</span>
                <span v-if="event.status == 0" class="text-white font-bold">Unarchive</span>

            </button>
        </div>

        <div v-if="event.show_comments" class="comments-container -mt-10">

            <comment class="w-2/3" :object="event" :type_id="18"></comment>

        </div>
    </div>

</template>

<script>

    



import BaseIcon from "../components/BaseIcon.vue"
import Comment from '../components/Comment';

export default {
    props: ['event', 'to_key', 'theme'],
    components: {
        BaseIcon,
        Comment
    },
    computed: {
        title () {
            return "News Event";
        }
    },
    data() {
        return {

        }
    },
    watch: {
    },
    mounted () {
        
    },
    methods: {

        
        
    }
}
</script>

<style lang="scss">

    


</style>