<template>

    <div v-if="entity" class="mt-3 mt-2 rounded-lg bg-white p-4 flex justify-between">

        <div class="flex items-center">
            <span class="mr-2"><h4>{{ to_key + 1 }}. </h4></span>
            <img v-if="entity.stats && entity.stats.cmc && entity.stats.cmc.meta" class="mr-5 h-10 w-10" :src="entity.stats.cmc.meta.logo">
            <h3 v-on:click="projectView(entity)" class="mr-5 underline cursor-pointer">
                {{entity.name}}
            </h3>

            <div class="" v-if="entity.votes">
                <span v-on:click="vote(entity, 'entity')" :class="(entity.voted) ? 'text-green' : ''" class="cursor-pointer">
                    <base-icon style="top: 9px" class="relative" :name="'voteUp'"></base-icon>
                </span>
                <small class="text-purple">
                    <span>{{entity.votes.length}}</span>
                    <span v-if="entity.votes.length > 1"> upvotes</span>
                    <span v-else> upvote</span>
                    <span v-if="entity.createdAt" class="text-purple"> | Added {{ entity.createdAt | moment("from", "now", true) }} ago</span>
                    <span v-if="entity.market_cap" class=""> | Market Cap: {{numberWithCommas(entity.market_cap)}}</span>

                </small>
            </div>
            <div class="basis-1/4 ml-3">
                <span v-if="entity.website" class="cursor-pointer ml-3 text-purple text-xs font-bold"><a target="_blank" :href=" ( entity.website ) ? 'http://'+entity.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '') : ''">Website<base-icon class="" :name="'link-up-right'"></base-icon></a></span>
                <span v-if="entity.stats && entity.stats.cmc && entity.stats.cmc.meta">
                    <span class="cursor-pointer ml-3 text-purple text-xs font-bold"><a target="_blank" :href="entity.stats.cmc.meta.urls.twitter[0]">Twitter Link<base-icon class="" :name="'link-up-right'"></base-icon></a></span>
                    <span v-if="entity.stats.cmc.meta.urls.source_code && entity.stats.cmc.meta.urls.source_code.length > 0" class="ml-3 cursor-pointer ml-3 text-purple text-xs font-bold"><a target="_blank" :href="entity.stats.cmc.meta.urls.source_code[0]">Source Code<base-icon class="" :name="'link-up-right'"></base-icon></a></span>
                    <span v-if="entity.stats.cmc.meta" class="ml-3 cursor-pointer ml-3 text-purple text-xs font-bold"><a target="_blank" :href="'https://coinmarketcap.com/currencies/' + entity.stats.cmc.meta.slug">View on Coin Market Cap<base-icon class="" :name="'link-up-right'"></base-icon></a></span>
                </span>
            </div>

        </div>


        <div class="float-right">
            <span class="mr-1 cursor-pointer circular-button" v-on:click="openAddTags(entity)">
                <span class="text-purple text-center">
                    <base-icon class="icon" :name="'tag'"></base-icon>
                </span>
            </span>
            
            <span class="mr-1 cursor-pointer circular-button" v-on:click="$emit('removeProject',entity)">
                <span class="">
                    <span class="text-purple undo-text" v-if="trashButtonText == 'Unarchive'">
                        <small>UNDO ARCHIVE</small>
                    </span>
                    <base-icon v-if="trashButtonText == 'Archive'" class="icon" :name="'archive'"></base-icon>
                </span>
            </span>

            <span v-on:click="suggestGem(entity)" class="mr-1 cursor-pointer circular-button">
                <span v-if="entity.status == 10" class="single-line text-purple undo-text"><small>{{suggest_button_text}}</small></span>
                <span v-if="entity.status != 10" class="text-purple"><base-icon class="icon" :name="'gem'"></base-icon></span>
            </span>
        

            <span v-if="!entity.stats || !entity.stats.cmc" class="mr-1 cursor-pointer circular-button" v-on:click="openEnrichment(entity)">
                <span class="text-purple text-center">
                    <base-icon class="icon" :name="'link-new'"></base-icon>
                </span>
            </span>

        </div>

    </div>
    
</template>

<script>

import BaseIcon from "../components/BaseIcon.vue"

export default {
    props: ['entity', 'to_key', 'theme'],
    components: {
        BaseIcon
    },
    computed: {
        title () {
            return "Project";
        },
        trashButtonText() {
            return ( this.entity.status != 0 ) ? "Archive" : "Unarchive";
        },
    },
    data() {
        return {
            suggested_text: "Suggested",
            unsuggest_text: "Undo",
            suggest_text: "Suggest as a potential gem",
            button_class: this.entity.button_class,
            suggest_button_text: this.entity.suggest_button_text,
        }
    },
    watch: {
    },
    mounted () {
        this.addButtonText();
    },
    methods: {

        projectView(entity) {
            history.pushState({}, null, `/entity/${entity.id}`);
            this.$emit('projectView', entity);
        },

        async vote(object) {

            var post_data = {
                "object_id": object.id,
                "user_id": this.$store.getters.me.id,
                "type_id": 21
            };

            const { data } = await this.$http.post(`/users/vote/new`, post_data);

            if ( data.action == "delete" ) {
                this.mixpanel.track("Unvoted Project", object);
                for (let item in object.votes) {
                    if ( object.votes[item].id == data.data.id ) {
                        object.votes.splice(item, 1);
                        object['voted'] = false;
                    }
                }

            } else {
                this.mixpanel.track("Voted on Project", object);
                object.votes.push(data.data);
                this.$set(object, "votes", object.votes);
                object.voted = true;
            }

            if ( this.themes )
                this.$emit('resortProjects', this.theme);
            else 
                this.$emit('resortProjects');

        },

        addButtonText() {

            this.suggest_button_text = ( this.entity.status == 10 ) ? this.suggested_text : this.suggest_text;

        },

        suggestButtonHover(entity, hovering) {
            if ( entity.status == 10 ) {
                if ( hovering ) {
                    this.suggest_button_text = this.unsuggest_text;
                    this.button_class = 'unsuggest-hover-state';
                } else {
                    this.suggest_button_text = this.suggested_text;
                }
            } else {
                this.button_class = "";
            }


        },

        async suggestGem(gem) {

            var suggest = ( gem.status == 1 ) ? true : false;

            const {data} = await this.$http.post(`/api/entities/suggest/gem`, {id: gem.id, suggest: suggest});

            if ( data.success && suggest ) {
                this.mixpanel.track("Suggested Gem", {name: gem.name});
                this.$set(gem, "status", 10);
                this.suggest_button_text = this.unsuggest_text;
                this.button_class = 'unsuggest-hover-state';

            } else {
                this.$set(gem, "status", 1);
                this.mixpanel.track("Unsuggested Gem", {name: gem.name});
                this.suggest_button_text = this.suggest_text;
                this.button_class = '';
            }

        },

        openEnrichment(entity) {
            this.$emit('openEnrichment', entity);
        },

        openAddTags(entity) {
            this.$emit('openAddTags', entity);
        }
        
    }
}
</script>

<style lang="scss">

    .circular-button {
        border-radius: 100px;
        width: 50px;
        height: 50px;
        border: 1px solid rgba(95, 98, 159, 0.2);
        display: inline-block;
        text-align: center;
        .icon {
            color: #5F629F;
            margin-top: 14px;
        }

        .undo-text {
            font-size: 10px;
            margin-top: 11px;
            display: inline-block;
            line-height: 1;

            &.single-line {
                margin-top: 19px;
            }
        }
    }


</style>