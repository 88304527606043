<template>
  <div>
    <div class="flex justify-between text-white font-bold p-5 bg-medium-purple py-10">
      <slot class=""></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  props: {
    header_text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
