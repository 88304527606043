<template>
    <div class="home">
    <spinner v-if="loading"></spinner>
    <header-bar class="">
        <h1 class="text-2xl">News</h1>
    </header-bar>
    <paginate @paginate="getData" :current_page="current_page" :total_results="events.count" :max_per_page="max_per_page" class="mt-3"></paginate>

    <select class="standard-border pr-3 ml-3 p-2 pl-3 rounded pr-0" v-model="simple_filter">
        <option v-bind:key="key" v-for="(simple_filter_option, key) of simple_filter_options" :value="simple_filter_option">{{simple_filter_option}}</option>
    </select>
    
    <div class="p-3 py-0" v-bind:key="to_key" v-for="(event,to_key) of events.rows">
        <news-tile @trashEvent="trashEvent" @vote="vote" :event="event" :to_key="to_key" class="standard-border mt-4 rounded-lg bg-white p-4 bg-white mb-3 p-5 pb-8 rounded-lg py-3 pt-6"></news-tile>
    </div>
    <paginate @paginate="getData" :current_page="current_page" :total_results="events.count" :max_per_page="max_per_page" class="mt-5"></paginate>
    </div>
</template>
<script>

import NewsTile from '../components/NewsTile';
import HeaderBar from '../components/HeaderBar';
import Spinner from '../components/Spinner';
import BaseIcon from '../components/BaseIcon';
import Paginate from '../components/Paginate';

export default {
    components: {
        NewsTile,
        HeaderBar,
        Spinner,
        BaseIcon,
        Paginate
    },
    watch: {
        simple_filter: function() {
            this.getData();   
        }
    },
    data() {
        return {
            search: '',
            loading: false,
            events: [],
            current_page: 1,
            max_per_page: 25,
            simple_filter: "non-voted",
            simple_filter_options: ["all","voted","non-voted", "archived"]
        }
    },
    mounted(){
        this.getData();  
    },
    methods: {


        async vote(object, type) {

            var post_data = {
                "object_id": object.id,
                "user_id": this.$store.getters.me.id,
                "type_id": 18
            };

            if ( typeof object.event_id != "undefined" )
                post_data['event_id'] = object.event_id;

            const { data } = await this.$http.post(`/users/vote/new`, post_data);

            if ( data.action == "delete" ) {
                this.mixpanel.track("Unvoted event", object);
                for (let item in object.votes) {
                    if ( object.votes[item].id == data.data.id ) {
                        object.votes.splice(item, 1);
                        object['voted'] = false;
                    }
                }

                this.resortEvents();

            } else {
                this.mixpanel.track("Voted on event", object);
                object.votes.push(data.data);
                this.$set(object, "votes", object.votes);
                object.voted = true;
                this.resortEvents();
            }
        },

        async trashEvent(event) {

            const status = ( event.status != 0 ) ? 0 : 1;

            const { data } = await this.$http.post(`api/events/delete-event/`, {event_id: event.id, status: status});

            for ( var key in this.events.rows) {
                if (this.events.rows[key].id == event.id) {
                    this.events.rows[key]['status'] = status;
                    var tracking_text = ( status != 0 ) ? "Archived event" : "Unarchived event";
                    this.mixpanel.track(tracking_text, event);
                }
            }
        },

        resortEvents() {
            this.events.rows.sort(function(a,b){
                return b.votes.length - a.votes.length;
            });
        },


        async getData(page = this.current_page) {
            this.loading = true;

            // get all entities
            const { data } = await this.$http.get(`/api/events/?page=${page}&max_results=${this.max_per_page}&simple_filter=${this.simple_filter}`);

            for ( var event of data.rows ) {
                event['show_comments'] = true;
                event['voted'] = false;

                for ( var vote of event.votes ) {
                    if ( vote.user_id == this.$store.getters.me.id ) {
                        event['voted'] = true;
                    }
                }
            }

            this.events = data;
            this.current_page = page;
            this.loading = false;

            this.resortEvents();

        }

    }
}
</script>

<style lang="scss">
    
</style>