<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
                    
            <form class="w-full max-w-lg">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    CMC Slug
                  </label>
                  <input autocomplete="off" v-model="slug" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-first-name" type="text" placeholder="CMC Slug">
                </div>
              </div>
            </form>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="map_cmc" data-modal-toggle="defaultModal" type="button" class="text-black bg-green-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
        </div>

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'entity'],
    components: {
    },
    computed: {
        title () {
            return "Add Slug";
        }
    },
    data() {
        return {
            slug: ""
        }
    },
    watch: {
    },
    mounted () {

    },
    methods: {
        async submitForm() {
            this.submitting = true;
            
        },
        clearForm() {
            
            
        },

        async map_cmc() {

            const { data } = await this.$http.get(`api/monitor/cmc/get_coin_by_slug/${this.entity.id}/${this.slug}`);

            if ( data.success ) {
                this.$set(this.entity, "stats", data.data);
                this.$emit('close');
                this.slug = "";

            }

        },
    }
}
</script>