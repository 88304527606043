<template>

    <div class="comments-container">
        <textarea placeholder="My thoughtful comment is..." v-model="active_comment" style="width: 100%" class="standard-border rounded p-2 bg-white mt-5 mb-1"></textarea>
        <button v-on:click="comment()" style="height: 40px" type="button" class="bg-light-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-white font-bold">Comment</span></button>
        
        <div class="mt-7 single-comment" v-bind:key="comment.id" v-for="comment of object.comments">

            <div v-if="comment.user">
                <div style="width: 50px;" class="mr-5 float-left profile-container overflow-hidden rounded-full">
                    <img :src="comment.user.avatar">
                </div>

                <div class="">
                    <p style="font-size: 15px" class="text-black font-bold">{{comment.user.first_name + " " + comment.user.last_name}}</p>
                    <p style="font-size: 13px" class="text-purple">{{ comment.createdAt | moment("from", "now", true) }} ago</p>

                    <p style="margin-left: 67px; font-size: 15px" class=" mt-3 text-black">{{comment.value}}</p>
                </div>
            </div>

        </div>


    </div>

</template>

<script>

import BaseIcon from "../components/BaseIcon.vue"

export default {
    props: ['object', 'type_id'],
    components: {
        BaseIcon,

    },
    computed: {
        
    },
    data() {
        return {
            active_comment: "",
        }
    },
    watch: {
    },
    mounted () {
        
    },
    methods: {
        async comment() {

            if ( this.active_comment != "" ) {
                var post_data = {
                    "object_id": this.object.id,
                    "user_id": this.$store.getters.me.id,
                    "type_id": this.type_id,
                    "value": this.active_comment
                };

                const { data } = await this.$http.post(`/users/comment/new`, post_data);

                var comments = ( this.object.comments ) ? this.object.comments : [];
                console.log(comments);
                comments.unshift(data.data);

                this.object.comments = comments;
                this.object.commented = true;
                this.active_comment = '';
                this.mixpanel.track("Commented", this.object);
            }

        },
        
    }
}
</script>

<style lang="scss">

    

</style>