<template>
  <div id="app">
    <component :is="layout">
      
      <router-view/>
      
      <template slot="body">
          
      </template>

    </component>

  </div>

</template>

<script>

  import "@fontsource/plus-jakarta-sans";
  const defaultLayout = "default";

  export default {
    components: {

    },

    data() {
      return {
        
      }
    },

    watch: {

      '$route': function(to, from) {
        this.mixpanel.track('Page Load - ' + this.$route.path);
      }
    },

    mounted(){
      //this.$store.dispatch('auth/logout');
      console.log('mounted mixpanel');
      this.$store.dispatch('auth/getMe');
      if ( typeof this.$store.getters.me.id != "undefined" ) {
        this.mixpanel.identify(this.$store.getters.me.id);
        this.mixpanel.people.set({ "public_address": this.$store.getters.me.public_address, "$first_name": this.$store.getters.me.first_name, "$last_name": this.$store.getters.me.last_name, "$id": this.$store.getters.me.id });
      }
      this.mixpanel.track('Page Load - ' + this.$route.path);
      

    },

    created() {

      this.$http.interceptors.response.use(undefined, (err) => {
        return new Promise((resolve, reject) => {
          if (err && err.response && err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
            if(err.response.data && err.response.data.logout) {
              console.log('forcing logout...');
              this.$store.dispatch('auth/setRedirect', this.$route.path);
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }

          throw err;
        });
      });
    },

    computed: {
      layout() {
            return (this.$route.meta.layout || defaultLayout) + "-layout";
        }
    }
  }
</script>

<style lang="scss">

  body, main, app {
    height: auto;
    min-height: 100%;
  }

  .tag-bubble {
        background: rgba(71, 233, 255, 0.1);
        border: none;
        color: #47E9FF;
        padding: 8px 11px;
        border-radius: 11px;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: .5px;

        &.dark {
            background: rgba(71, 233, 255, 0.8) !important;
            color: #333;
        }
    }

  * {
      font-family: "Plus Jakarta Sans";
  }

</style>
