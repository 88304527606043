<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
                    
        <form class="w-full max-w-lg">
            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3 mb-6 md:mb-0">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        {{theme.name}} Features
                    </label>
                    
                    <div v-bind:key="feature.id" v-for="feature of features">
                        <input 
                            :disabled="is_it_standard(feature.id) == 1"
                            type="checkbox"
                            v-model="theme.checked_features"
                            :value="feature.id"
                            :false-value="false"
                            class="checked:bg-blue-500 mr-2"
                        >
                        {{feature.name}}
                    
                    </div>

                </div>

            </div>
        </form>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="saveFeatureChanges" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500">Save</button>
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500">Close</button>
        </div>
        

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'theme', 'features'],
    components: {
    },
    computed: {
        title () {
            return "Edit Features";
        }
    },
    data() {
        return {
            feature_creation: {
                
            },
        }
    },
    watch: {
        theme: function() {
        }
    },

    created() {
    },
    mounted () {
    },
    methods: {

        is_it_standard(feature_id) {

            for ( var feature of this.features ) {
                if ( feature.id == feature_id ) {
                    return feature.is_standard;
                }
            }

        },
        
        async saveFeatureChanges() {

            var features_to_save = [];
            
            for ( var final_feature_id of this.theme.checked_features ) {
                if ( !this.is_it_standard(final_feature_id) )
                    features_to_save.push(final_feature_id);
            }

            var payload = {features: features_to_save, theme_id: this.theme.id};
            const { data } = await this.$http.post(`/api/themes/update-features`, payload);

            if (data.success) {
                this.$emit('updateThemeFeatures', this.theme.id);
                this.$emit('close');
                this.mixpanel.track("Edited Feature", payload);
            }

        }
    }
}
</script>

<style>
    .method-select {
        border: #555 solid;
        border-width: 1px;
        padding: 7px;
        border-radius: 3px;
    }


</style>