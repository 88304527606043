import Vue from 'vue';
import axios from 'axios';

export const auth = {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem('user')) || {},
        token: localStorage.getItem('access_token') || null,
        redirect: null,
        me: {},
        isSessionLoaded: false
    },
    mutations: {
        setUser(state, val) {
            state.user = val;
            state.token = val.access_token;
            Vue.prototype.$http.defaults.headers.common['x-access-token'] = val.access_token;
        },
        setRedirect(state, val) {
            state.redirect = val;
        },
        setIsSessionLoaded(state, val) {
            state.isSessionLoaded = val;
        },
        logout(state) {
            state.user = '';
            state.token = '';
        },
        setMe(state, val){
            state.me = val;
        },
    },
    actions: {
        async login({ commit }, user) {
            console.log('the user: ');
            console.log(user);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('access_token', user.access_token);
            await commit('setUser', user);
        },
        async setRedirect({commit}, path) {
            await commit('setRedirect', path);
        },
        async setUser({commit}, payload) {
            await commit('setUser', payload);
        },
        async logout({commit}){
            commit('logout');
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            delete axios.defaults.headers.common['x-access-token'];
        },
        async getMe({commit}){

            await axios.get('/api/me').then(response => {
                if(response.data){
                    commit('setMe', response.data)
                }
            }).catch(err => {
                console.log(err);

            }).finally(() => {
                commit('setIsSessionLoaded', true)
            })
        }
    },
    modules: {
    },
    getters : {
        isLoggedIn: state => !!state.token
    }
}