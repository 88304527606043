<template>

    <base-modal
        :visible="visible"
        :title="title"
        :entity="entity"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
            
            
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                Tags
              </label>

              <div class="tag-container">
                <div v-for="activeTag of activeTags" v-bind:key="activeTag.id" class="inline-block p-2 m-1 ml-0 bg-gray-50 rounded-md">
                    <small>{{activeTag.name}}<span class="ml-3 cursor-pointer" v-on:click="removeTag(activeTag)">x</span></small>
                </div>
              </div>

              <input autocomplete="off" v-model="tagText" v-on:keyup.enter="add_new_tag($event.target.value)" v-on:keyup="autoCompleteTags($event.target.value)" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-first-name" type="text" placeholder="Tag">

              <div v-if="tagMatches.length > 0" class="fixed bg-gray-50 p-1 sm:w-2/4 md:w-1/4">
                  <div v-for="match of tagMatches" v-bind:key="match.id">
                      <div v-on:click="addTag(match)" :id="match.name" class="p-2 cursor-pointer hover:bg-gray-100">{{match.name}}</div>
                  </div>

              </div>
            </div>
            
          </div>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="saveTags" data-modal-toggle="defaultModal" type="button" class="text-black bg-green-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
            <button v-on:click="closeModal" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
        </div>

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'tags', 'entity', 'theme_id'],
    components: {
    },
    computed: {
        title () {
            return "Update Tags For " + this.entity.name;
        }
    },
    data() {
        return {
            tagText: "",
            tagMatches: [],
            activeTags: []
        }
    },
    watch: {

        entity: function() {
            this.mergeTags();
        }

    },
    mounted () {


    },
    methods: {

        mergeTags() {
            this.activeTags = [];
            var final = [];

            if ( this.entity.tag_objects.length > 0 ) {
                for ( var tag_object of this.entity.tag_objects ) {
                    final.push(tag_object.tag);
                }
            }

            this.activeTags = final.concat(this.activeTags);

        },

        async submitForm() {
            this.submitting = true;
            
        },
        clearForm() {
            
            
        },

        add_new_tag(value) {

            var matches = this.activeTags.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));

            if ( matches.length == 0 ) {
                this.activeTags.push({'name': value, 'id': null});
                this.tagMatches = [];
                this.tagText = "";                
            }

        },

        autoCompleteTags(value) {
            if ( value.length > 2 ) {
                this.tagMatches = this.tags.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.tagMatches = [];
            }

        },

        addTag(tag) {

            this.activeTags.push(tag);
            this.tagMatches = [];
            this.tagText = "";

        },

        async saveTags() {

            var post_data = {
                "object_id": this.entity.id,
                "tags": this.activeTags,
                "type_id": 13
            };

            const { data } = await this.$http.post(`/api/entities/save-tags`, post_data);
        
            if (data.success) {
                this.$emit('close');
                this.$emit('updateThemes', this.theme_id, this.activeTags, this.entity);
                this.$emit('updateEntity', this.activeTags, this.entity);
                this.tagMatches = [];
                this.tagText = ""; 
            }

        },

        removeTag(tag) {
            var new_arr = [];
            for ( var active_tag of this.activeTags ) {
                if ( active_tag.name != tag.name ) {
                    new_arr.push(active_tag);
                }
            }

            this.activeTags = new_arr;

        },

        closeModal() {
            this.$emit('close');
            this.activeTags = [];
            this.mergeTags();

        }
    }
}
</script>