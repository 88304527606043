<template>

    <div class="text-center">
        <div><small><i>Page {{current_page}}</i></small></div>
        <span v-on:click="changePage(current_page-1)" :class="(current_page == 1) ? 'disabled' : ''" class="end pagination-button cursor-pointer"><base-icon style="color: #C4CDD5" :name="'previous-page'"></base-icon></span>
        <!--
        <span v-if="current_page > parseInt(total_pages)/2" v-on:click="changePage(1)"><span :class="( current_page == 1 ) ? 'active' : ''" class="cursor-pointer pagination-button ml-1">1</span></span>
        <span v-if="current_page > parseInt(total_pages)/2" v-on:click="changePage(2)"><span :class="( current_page == 2 ) ? 'active' : ''" class="cursor-pointer pagination-button ml-1">2</span></span>

        <span v-on:click="changePage(key)" v-bind:key="key+Math.random(1,100)" v-for="key in total_pages" ><span v-if="key >= current_page && key < current_page+2 && key < total_pages/2" :class="( current_page == key ) ? 'active' : ''" class="cursor-pointer pagination-button ml-1">{{key}}</span></span>

        <span><span class="cursor-pointer pagination-button ml-1">...</span></span>
        <span v-on:click="changePage(key)" v-bind:key="key+Math.random(1,100)" v-for="key in total_pages" ><span v-if="key > total_pages-3 && key != total_pages" :class="( current_page == key ) ? 'active' : ''" class="cursor-pointer pagination-button ml-1">{{key}}</span></span>
        -->
        <span v-on:click="changePage(current_page+1)"  :class="((current_page * max_per_page) > total_results) ? 'disabled' : ''" class="cursor-pointer end pagination-button ml-1"><base-icon style="color: #C4CDD5" :name="'next-page'"></base-icon></span>
        <div><small><i>{{numberWithCommas(total_results)}} Results</i></small></div>
    </div>

</template>

<script>

import BaseIcon from "../components/BaseIcon.vue"

export default {
    props: ['total_results', 'current_page', 'max_per_page'],
    components: {
        BaseIcon
    },
    computed: {
        total_pages () {
            return parseInt(this.total_results/this.max_per_page);
        }
    },
    data() {
        return {

        }
    },
    watch: {
    },
    mounted () {
        
    },
    methods: {

        changePage(page) {
            if ( page > 0 )
                this.$emit('paginate', page);
        }
        
    }
}
</script>

<style lang="scss">

    .pagination-button {
        padding-top: 4px;
        display: inline-block;
        text-align: center;
        width: 35px;
        height: 35px;
        border-width: 1px;
        border-color: #919EAB;
        border-radius: 4px;
        color: #000000;
        background: #fff;

        &.end {
            
        }
        &.disabled {
            background: #919EAB;
            cursor: not-allowed;
        }

        &.active {
            border: 1px solid #4E31FF;
            color: #4E31FF;
        }
    }


</style>