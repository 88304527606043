<template>
    <!-- https://codepen.io/team/codepen/pen/axwMPo -->
    <div>
        <!-- overlay -->
        <transition name="fade" appear>
            <div v-if="visible" @click="$emit('close')" class="modal-overlay"/>
        </transition>

        <!-- modal -->
        <transition name="pop" appear>
            <div v-if="visible" :class="['modal', this.classes, {'max-w-3xl': !withPicture, 'max-w-4xl': withPicture}]" :style="`${this.styles}`" role="dialog">
                <div class="flex">
                    <div v-if="withPicture" class="w-1/3 flex rounded-l-2xl flex-col justify-between" :class="{'border-purple-m-secondary border-1 bg-purple-m-secondary text-white': mode === 'dark'}">
                        <div class="p-6 flex flex-col gap-4">
                            <div class="text-h2 font-bold">{{ modalTitle }}</div>
                            <div class="text-sm font-light">{{ modalSubtitle }}</div>
                        </div>
                    </div>
                    <div class="w-full flex flex-col justify-between">
                        <div v-if="!hideHeader" class="pt-10 pb-5 px-14 relative flex justify-between items-center">
                            <div class="flex justify-start items-center">
                                <slot name="title-icon"></slot>
                                <h3 class="text-2xl leading-6 font-bold text-black flex items-center">
                                    {{ title }}
                                </h3>
                            </div>
                        </div>

                        <div :class="{'pt-5 pb-10 px-14': !noPadding}">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        props: {
            visible: {},
            title: {},
            hideHeader: {},
            classes: {},
            styles: {},
            withPicture: {
                type: Boolean,
                default: false
            },
            modalTitle: {},
            modalSubtitle: {},
            modalIcon: {},
            mode: {
                type: String,
                default: 'dark'
            },
            noPadding: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                
            }
        }
    }
</script>

<style scoped lang="scss">

    .modal {
        background: white;
        @apply fixed inset-0 m-auto rounded-2xl shadow-lg z-50 transform-none w-full;
        height: fit-content;
    }

    .modal h1 {
        margin: 0 0 1rem;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.2s linear;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .modal-overlay {
        @apply fixed inset-0 z-40 cursor-pointer;
        content: '';
        background-color: rgb(14 9 44 / 90%);
    }

    .pop-enter-active, .pop-leave-active {
        transition: transform 0.2s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
    }

    .pop-enter, .pop-leave-to {
        opacity: 0;
        transform: scale(0.3) translateY(-50%);
    }

</style>