import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.store';
import { loader } from './loader.store';
import { restorer } from './restorer.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        loader,
        restorer
    },
    state: {
        dialog: {
            title: '',
            description: '',
            show: false,
            action: null
        }
    },
    mutations: {},
    getters: {
        isLoggedIn() {
            return !!auth.state.token;
        },

        user() {
            return auth.state.user;
        },

        loader() {
            return loader.state.visible;
        },

        restorer() {
            return restorer.state;
        },

        me() {
            return auth.state.me;
        },

        isSessionLoaded() {
            return auth.state.isSessionLoaded;
        },

        redirect() {
            return auth.state.redirect
        },

        accessToken() {
            return auth.state.token;
        }
    }
});