<template>
    <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">

        <template>
            <v-card-title>
                Entities
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="[
                    { text: 'Name', value: 'name' },
                    { text: 'Website', value: 'website' },
                    { text: 'Twitter Handle', value: 'twitter_username' },
                    { text: 'Twitter Followers', value: 'twitter_followers' }
                ]"
                :items="entities"
                :items-per-page="50"
                :search="search"
                class="elevation-1 text-left"
            ></v-data-table>
        </template>

    </main>
</template>
<script>

export default {
    components: {
        
    },
    data() {
        return {
            search: '',
            loading: false,
            entities: [],
            tags: [],
        }
    },
    mounted(){
        this.getData();  
        this.getTags();      
    },
    methods: {
        async getData(showLoader = true) {
            this.loading = showLoader ? true : false;

            // get all entities
            const { data } = await this.$http.get('/api/entities/');
            this.entities = data;

            // merge networks
            this.entities.map(e => {

                e.twitter_username = null;
                e.twitter_followers = 0;

                e.network_objects.forEach(up => {

                    switch(up.network.name) {
                        case 'twitter':
                            if ( up.type_id == 13 ) {
                                e.twitter_username = up.username;
                                e.twitter_followers = ( up.followers == null || isNaN(up.followers) ) ? 0 : parseInt(up.followers);
                                break;
                            }
                    }

                });
            });

            this.loading = false;
        },
        async getTags(){
            //const { data } = await this.$http.get(`/api/tags`)
            //this.tags = data
        }

    }
}
</script>