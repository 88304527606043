<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
            {{description}}
        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
        </div>

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'description'],
    components: {
    },
    computed: {
        title () {
            return "Description";
        }
    },
    data() {
        return {
            
        }
    },
    watch: {
    },
    mounted () {

    },
    methods: {
        
    }
}
</script>