<template>
  <div class="home">
    <header-bar class="">
        <h1 class="text-2xl">Themes</h1>
        <button v-on:click="theme_modal_show()" type="button" class="bg-button-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-light-purple font-bold">Add Theme</span></button>
    </header-bar>

    <div class="">

        <theme-modal
            :visible="themeModalVisible"
            :tags="tags"
            @close="themeModalVisible = false">
        </theme-modal>

        <description-modal
            :visible="descriptionModalVisible"
            :description="activeDescription"
            @close="descriptionModalVisible = false">
        </description-modal>

        <enrichment-modal
            :visible="enrichmentModalVisible"
            :entity="activeEntity"
            @close="enrichmentModalVisible = false">
        </enrichment-modal>

        <edit-feature-modal
            v-if="Object.values(active_theme).length != 0"
            :visible="editFeaturesModalVisible"
            :theme="active_theme"
            :features="features"
            @updateThemeFeatures="updateThemeFeatures"
            @close="editFeaturesModalVisible = false">
        </edit-feature-modal>

        <add-tags-modal
            v-if="activeEntity"
            :visible="addTagsModalVisible"
            :entity="activeEntity"
            :tags="tags"
            :theme_id="active_theme_id"
            @close="addTagsModalVisible = false"
            @updateThemes="updateThemes"
        >
        </add-tags-modal>

        <add-tags-modal-themes
            v-if="Object.keys(active_theme).length > 0"
            :visible="addTagsModalThemesVisible"
            :theme="active_theme"
            :tags="tags"
            @close="addTagsModalThemesVisible = false"
            @addTagsToTheme="addTagsToTheme"
        >
        </add-tags-modal-themes>


        <spinner v-if="isLoading"></spinner>
        <div v-if="!singleView && themes" id="tag-container">
            
            <div class="p-5 py-0" v-for="theme in themes" :key="theme.id">
                <div>
                    <div class="my-2 flex flex-row pb-0 pl-2 bg-dark-purple py-3 rounded-lg">
                        <div class="basis-1/4 ml-3">
                            <div class="grid grid-cols-1 flex items-center">
                                <div>
                                    <h3 v-if="!theme.update_name" class="float-left text-white text-lg font-bold pb-0">{{theme.name}}</h3>
                                    <base-icon v-if="!theme.update_name" :iconSize="10" class="cursor-pointer mt-1 ml-1 text-grey" :name="'edit'"></base-icon>
                                    <a v-if="!theme.update_name" style="font-size: 10px" class="cursor-pointer mt-1 text-sm text-grey uppercase" v-on:click="editThemeName(theme)">edit name</a>
                                    <span v-if="theme.update_name">
                                        <input class="bg-white p-1" v-model="pendingThemeName" type="" name="">
                                        <button v-on:click="updateName" class="text-sm font-bold mb-3 text-white p-2">Save</button>
                                    </span>
                                </div>
                            </div>


                            <div v-if="theme.votes" class="text-grey pt-0">
                                <span v-on:click="vote(theme, 'theme')" :class="(theme.voted) ? 'text-green' : ''" class="cursor-pointer">
                                    <base-icon style="top: 9px" :size="5" class="relative" :name="'voteUp'"></base-icon>
                                </span>
                                <small>
                                    <span>{{theme.votes.length}} </span>
                                    <span v-if="theme.votes.length > 1"> upvotes</span>
                                    <span v-else> upvote</span>
                                </small>
                                <base-icon :iconSize="10" class="cursor-pointer mt-1 ml-3 text-grey" :name="'edit'"></base-icon>
                                <a style="font-size: 10px" class="cursor-pointer mt-1 text-sm text-grey uppercase" v-on:click="openFeatureModal(theme)">edit features</a>
                            </div>

                            <div class="float-left" v-for="(tab,key) of theme_tabs" :key="key" :class="[{'tab-selected': ( theme.selected_tab == key )}, { 'ml-6': ( key != 'news' )}]">
                                <button v-on:click="switchTabs(theme, key)" class="text-sm font-bold mb-3 text-white p-2 mt-6 basis-1/4">
                                    {{tab.display_name}} 
                                    <small v-if="theme.unique_events && key == 'news'" class="ml-0">({{theme.unique_events.length}})</small>
                                    <small v-if="theme.entities && key == 'projects' && theme.entities" class="ml-0">({{theme.entities.length}})</small>
                                    <small v-if="theme.social_data && key == 'social'" class="ml-0">({{theme.social_data.twitter.count}})</small>
                                    
                                </button>
                                <div v-if="theme.selected_tab == key" class="active-tab relative border-solid border"></div>
                            </div>
                        </div>
                        <div class="basis-2/4" v-for="(tag, the_key) of theme.tags" :key="the_key">
                            <small class="tag-bubble ml-4">{{tag.name}}</small>
                        </div>
                        <base-icon :iconSize="10" class="cursor-pointer mt-1 ml-3 text-grey" :name="'edit'"></base-icon>
                        <a style="font-size: 10px" class="cursor-pointer mt-1 text-sm text-grey uppercase" v-on:click="editTagsThemes(theme)">edit tags</a>
                        <div class="basis-2/4 grow flex justify-end items-center mr-5">
                            <a class="text-white text-xl" v-on:click="toggle(theme)" ><span v-if="!theme.collapsed" class="mdi mdi-chevron-down"></span><span v-if="theme.collapsed" class="mdi mdi-chevron-up"></span></a>
                                
                        </div>
                    </div>
                    <div v-if="!theme.collapsed">
                        <div v-if="theme.selected_tab == 'news'">
                            <div v-for="(event, event_key) in theme.unique_events" :key="event_key">
                                <news-tile @vote="vote" :to_key="event_key" :event="event" class="mt-4 standard-border rounded-lg bg-white p-4 bg-white mb-3 p-5 rounded-lg py-3 pt-6"></news-tile>
                            </div>
                        </div>

                        <div v-if="theme.selected_tab == 'projects'">

                            <div class="text-center" v-if="theme.entities && theme.entities.length == 0"><i>No Projects Found</i></div>

                            <span v-if="theme.entities">
                                <div class="" v-bind:key="entity.id" v-for="(entity,to_key) of theme.entities">
                                    <project-tile class="standard-border" @projectView="projectView" :themes="theme" :id="'entity-'+entity.id" @resortProjects="resortProjects" @openEnrichment="openEnrichment" @openAddTags="openAddTags(entity, theme.id)" @removeEntity="removeEntity" :entity="entity" :to_key="to_key"></project-tile>
                                </div>
                            </span>



                        </div>

                        <div v-if="theme.selected_tab == 'social'">
                            <div v-if="theme.social_data">
                                <div class="container" v-bind:key="tag_results.id" v-for="tag_results of theme.social_data.twitter.posts">

                                    <div class="pl-0 p-5 item" v-bind:key="status.id" v-for="status of tag_results">

                                        <!-- component -->
                                        <div class="bg-gray-50 dark:bg-black flex">
                                            <div class="bg-white dark:bg-gray-800 standard-border p-4 rounded-xl border max-w-xl">
                                                <div class="flex justify-between">
                                                    <div class="flex items-center">
                                                        <img class="h-11 w-11 rounded-full" :src="status.user.profile_image_url"/>
                                                        <div class="ml-1.5 text-sm leading-tight">
                                                            <span class="text-black dark:text-white font-bold block ">{{status.user.name}}</span>
                                                            <span class="text-gray-500 dark:text-gray-400 font-normal block">@{{status.user.screen_name}}</span>
                                                        </div>
                                                    </div>
                                                    <svg class="text-blue-400 dark:text-white h-6 w-auto inline-block fill-current" viewBox="0 0 24 24"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
                                                </div>
                                                <p class="text-black dark:text-white block text-xl leading-snug mt-3">{{status.text}}</p>
                                                <!-- img class="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700" src="https://pbs.twimg.com/media/EpkuplDXEAEjbFc?format=jpg&name=medium"/ -->
                                                <p class="text-gray-500 dark:text-gray-400 text-base py-1 my-0.5">{{ status.created_at | moment("from", "now", true) }} ago</p>
                                                <div class="border-gray-200 dark:border-gray-600 border border-b-0 my-1"></div>
                                                <div class="text-gray-500 dark:text-gray-400 flex mt-3">
                                                    <div class="flex items-center mr-6">
                                                        <!-- svg class="fill-current h-5 w-auto" viewBox="0 0 24 24" class="r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr" style=""><g><path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"></path></g></svg -->
                                                    </div>
                                                    <div class="flex items-center mr-6">
                                                        <!-- svg class="fill-current h-5 w-auto" viewBox="0 0 24 24" class="r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"><g><path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path></g></svg -->
                                                        <span class="ml-3">{{status.retweet_count}} retweets</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="singleView">
            
            <project @openEnrichment="openEnrichment" @openAddTags="openAddTags" @toggleProject="toggleProject" :entity="activeEntity"></project>

        </div>
    </div>

    </div>
</template>
<script>

    import ThemeModal from '../components/ThemeModal';
    import DescriptionModal from '../components/DescriptionModal';
    import EnrichmentModal from '../components/EnrichmentModal';
    import ProjectTile from '../components/ProjectTile';
    import NewsTile from '../components/NewsTile';
    import HeaderBar from '../components/HeaderBar';
    import Spinner from '../components/Spinner';
    import BaseIcon from '../components/BaseIcon';
    import EditFeatureModal from '../components/EditFeatureModal';
    import AddTagsModal from '../components/AddTagsModal';
    import AddTagsModalThemes from '../components/AddTagsModalThemes';
    import Project from '../components/Project';

    export default {
        name: 'Themes',
        components: {
            ThemeModal,
            Project,
            Spinner,
            HeaderBar,
            BaseIcon,
            DescriptionModal,
            EnrichmentModal,
            EditFeatureModal,
            ProjectTile,
            NewsTile,
            AddTagsModal,
            AddTagsModalThemes
        },
        data() {
            return {
                active_theme: {},
                pendingThemeName: "",
                suggested_text: "Suggested as a potential gem",
                unsuggest_text: "Unsuggest as a potential gem",
                suggest_text: "Suggest as a potential gem",
                descriptionModalVisible: false,
                activeEntity: {},
                themeModalVisible: false,
                enrichmentModalVisible: false,
                addTagsModalVisible: false,
                addTagsModalThemesVisible: false,
                active_theme_id: 0,
                activeDescription: "",
                checked_features: [],
                features: {},
                singleView: false,
                themes: [],
                tags: [],
                entities: [],
                theme_tabs: {
                    //TEMPORARILY REMOVING NEWS TO SPEED THINGS UP AND SAVE MONEY
                    /*"news": {
                        "display_name": "News",
                    },*/ 
                    "projects": {
                        "display_name": "Projects",
                    },
                    "social": {
                        "display_name": "Social",
                    }
                },
                isLoading: false,
                editFeaturesModalVisible: false
            }
        },

        computed: {
            
        },
        watch: {

        },

        beforeMount() {

        },

        mounted() {
            this.getThemes();
            this.getTags();
            this.getFeatures();

        },

        methods: {

            theme_modal_show() {
                
                this.themeModalVisible = true;

            },

            editThemeName(act_theme) {
                this.active_theme = act_theme;
                this.pendingThemeName = this.active_theme.name;

                for ( var theme of this.themes ) {
                    if ( theme.id == act_theme.id ) {

                        theme.update_name = true;

                    }
                }

            },

            async updateName() {

                const {data} = await this.$http.post(`/api/themes/update-name`, {name: this.pendingThemeName, id: this.active_theme.id });

                if ( data.success ) {
                    this.active_theme.name = this.pendingThemeName;
                    this.pendingThemeName = "";

                    for ( var key in this.themes ) {
                        if ( this.themes[key].id == this.active_theme.id ) {
                            this.$set(this.themes[key], 'update_name', false);
                        }
                    }
                }

            },

            toggleProject() {
                this.singleView = !this.singleView;
            },

            projectView(entity) {
                console.log('getting here');
                this.singleView = true;
                this.activeEntity = entity;
            },

            addTagsToTheme(data) {

                for ( var key in this.themes ) {

                    if ( this.themes[key].id == data.theme_id ) {

                        this.$set(this.themes[key], 'tags', data.tags);

                    }

                }

            },

            editTagsThemes(theme) {
                console.log(theme);
                this.active_theme = theme; 
                this.addTagsModalThemesVisible = true

            },

            resortProjects(changing_theme = false) {

                for ( var key in this.themes ) {
                    if ( changing_theme && this.themes[key].id == changing_theme.id ) {
                        var entities = this.themes[key].entities;
                        entities.sort(function(a,b){
                            return b.votes.length - a.votes.length;
                        });
                    } else {
                        entities = this.themes[key].entities;
                        entities.sort(function(a,b){
                            return b.votes.length - a.votes.length;
                        });
                    }


                    for ( var item of this.themes[key].entities ) {

                        item['voted'] = false;

                        for ( var vote_key in item['votes'] ) {
                                
                            if (item['votes'][vote_key].user_id == this.$store.getters.me.id )
                                item['voted'] = true;
                        }
                    }


                }

            },

            async updateThemeFeatures(theme_id) {
                this.getThemes(true);
            },

            async updateThemes(theme_id, tags, _entity) {
                
                const {data} = await this.$http.get(`/api/entities/entity/` + _entity.id);

                for ( var theme of this.themes ) {

                    if ( theme.id == theme_id ) {
                        for ( var key in theme.entities ) {
                            if ( theme.entities[key].id == _entity.id ) {
                                this.$set(theme.entities, key, data);
                            }
                        }
                    }
                }

            },

            updateFeatures() {

                for ( var theme of this.themes ) {

                    var checked_features = [];
                    for ( var theme_feature of theme.theme_features ) {
                        checked_features.push(theme_feature.feature_id);
                    }

                    for ( var feature of this.features ) {
                        if ( feature.is_standard ) {
                            checked_features.push(feature.id);
                            
                            
                        }
                    }

                    theme["checked_features"] = checked_features;

                }

            },

            removeEntity(id) {

                for ( var theme of this.themes ) {
                    for ( var key in theme.entities) {
                        if (theme.entities[key].id == id) {
                            theme.entities.splice(key, 1);
                        }

                    }
                }

            },

            async openFeatureModal(theme) {

                await this.updateFeatures();

                this.active_theme = theme;
                this.editFeaturesModalVisible = true;
            },

            openEnrichment(entity) {
                this.activeEntity = entity;
                this.enrichmentModalVisible = true;

            },

            openAddTags(entity, theme_id) {
                this.active_theme_id = theme_id;
                this.activeEntity = entity;
                this.addTagsModalVisible = true;

            },

            async getTagsForTheme() {

                const {data} = await this.$http.get(`/api/themes/projects/`);

                for ( var theme of data ) {
                    
                    var theme_tags = [];
                    var tags = [];
                    var used_entities = {};
                    for ( var theme_tag of theme.theme_tags ) {
                        tags.push(theme_tag.tag);
                        theme_tags.push(theme_tag);
                        
                        if ( theme_tag.tag.tag_objects.length > 0) {

                            for ( var tag_object of theme_tag.tag.tag_objects ) {
                                tag_object.entities[0].tag_object = tag_object;
                                tag_object.entities[0]['suggest_button_text'] = ( tag_object.entities[0].status == 10 ) ? this.suggested_text : this.suggest_text;
                                tag_object.entities[0]['button_class'] = "";
                                used_entities[tag_object.entities[0].id] = tag_object.entities[0];
                            }
                        }
                    }

                    for ( var t of this.themes ) {

                        if ( t.id == theme.id ) {
                            this.$set(t, "entities", Object.values(used_entities));
                            this.$set(t, "tags", tags);
                            this.$set(t, "theme_tags", theme_tags);
                        }
                    }
                }   
            },

            async getFeatures() {
                const { data } = await this.$http.get(`/themes/features`);
                this.features = data;
            },

            async getSocialData(theme) {
                this.isLoading = true;
                var tags = theme.tags;
                const { data } = await this.$http.get(`/api/themes/get-tweets?tags=` + tags);
                var final_arr = [];

                for ( var item of data ) {
                    for ( var status of item.statuses ) {
                        final_arr.push(status);
                    }
                }

                this.$set(theme, 'social_data', {"twitter": { "posts": this.chunkArray(final_arr, 5), "count": final_arr.length}});
                this.isLoading = false;

            },

            async getTags() {

                const { data } = await this.$http.get(`/api/tags/all`);
                this.tags = data;

            },

            async removeEvent(event, theme) {
                this.mixpanel.track("Marked Event as Irrelevant", event);
                const { data } = await this.$http.post(`api/themes/event/remove/`, {event_id: event.id, theme_id: theme.id});

                for ( var key in theme.unique_events) {
                    if (theme.unique_events[key].id == event.id) {
                        theme.unique_events.splice(key, 1);
                    }

                }

            },

            async getThemes(run_loader = true, collapse = true, selected_tab = 'news', theme_id = 0){
                this.themes = [];
                this.isLoading = (run_loader) ? true : false;

                const { data } = await this.$http.get(`/api/themes/`);

                for ( var item of data ) {

                    var event_final = {};


                    item['collapsed'] = ( theme_id == item.id ) ? false : collapse;

                    item['voted'] = false;
                    item['event_count'] = 0;

                    for ( var vote_key in item['votes'] ) {
                            
                        if (item['votes'][vote_key].user_id == this.$store.getters.me.id )
                            item['voted'] = true;
                    }

                    //TEMPORARILY REMOVING NEWS TO SPEED THINGS UP AND SAVE MONEY
                    /*
                    for ( var theme_event of item.theme_events ) {
                        var event = theme_event.event;
                        if (event && event.link.indexOf("~~~~") ) {
                            event.link =  event.meta.url;
                            item['event_count']++;
                        }

                        if (event) {
                            event_final[event.meta.url] = event;
                        }

                        var new_vote_arr = [];

                        for ( var vote_key_ in event['votes'] ) {

                            if ( event['votes'][vote_key_].type_id == 18 && event['votes'][vote_key_].user_id == this.$store.getters.me.id ) {
                                event['voted'] = true;
                            }

                            new_vote_arr.push(event['votes'][vote_key_]);
                        }

                        event['votes'] = new_vote_arr;
                        event['show_comments'] = false;
                        event['existing_comment'] = '';
                    }
                    */
                    item['update_name'] = false;

                    var final_events_ = Object.keys(event_final)
                        .map(function(key) {
                            return event_final[key];
                    });

                    item.unique_events = final_events_;
                    item.selected_tab = selected_tab;
                }

                this.themes = data;

                await this.resortThemes();
                await this.resortEvents();

                await this.getTagsForTheme();
                this.resortProjects();

                this.isLoading = false;
            },
            
            toggle(theme) {
                theme['collapsed'] = !theme['collapsed'];
            },

            async vote(object, type) {

                var type_id = ( type == "theme" ) ? 19 : 18;

                var post_data = {
                    "object_id": object.id,
                    "user_id": this.$store.getters.me.id,
                    "type_id": type_id
                };

                if ( typeof object.event_id != "undefined" )
                    post_data['event_id'] = object.event_id;

                const { data } = await this.$http.post(`/users/vote/new`, post_data);

                if ( data.action == "delete" ) {
                    this.mixpanel.track("Unvoted Theme", {name: object.name});
                    for (let item in object.votes) {
                        if ( object.votes[item].id == data.data.id ) {
                            object.votes.splice(item, 1);
                            object['voted'] = false;
                        }
                    }

                } else {
                    this.mixpanel.track("Voted Theme", {name: object.name});
                    object.votes.push(data.data);
                    this.$set(object, "votes", object.votes);
                    object.voted = true;
                    
                    if ( type_id == 19 ) {
                        this.resortThemes();
                    } else {
                        this.resortEvents();
                    }


                }
            },

            resortEvents() {
                for ( var theme of this.themes ) {
                    theme.unique_events.sort(function(a,b){
                        return b.votes.length - a.votes.length;
                    });
                }
            },

            resortThemes() {
                this.themes.sort(function(a,b){
                    return b.votes.length - a.votes.length;
                });
            },
            switchTabs(theme, key) {
                theme.selected_tab = key;

                if ( theme.selected_tab == "social" ) {
                    this.getSocialData(theme);
                }

                //if ( theme.selected_tab == "projects" ) {
                    //this.getTagsAndProjectsForTheme(theme);
                //}

            }
        }

    }
</script>

<style lang="scss" scoped>

    textarea {
        background: #F6F6F6;
        border-radius: 4px;
        border: #d5d6de solid;
    }

    .container {
      display: flex;
      flex-flow: column wrap;
      align-content: space-between;
      /* Your container needs a fixed height, and it 
       * needs to be taller than your tallest column. */
      height: 390px; 
      max-width: 100% !important;
    }

    .item {
      width: 20%;
      margin-bottom: 2%; /* Optional */
    }

    /* Re-order items into 3 rows */
    .item:nth-child(3n+1) { order: 1; }
    .item:nth-child(3n+2) { order: 2; }
    .item:nth-child(3n)   { order: 3; }

    /* Force new columns */
    .container::before,
    .container::after {
      content: "";
      flex-basis: 100%;
      width: 0;
      order: 2;
    }

    .text-grey {
        color: lightgrey;
    }

    .active-tab {
        top: 0px !important;

    }
    
</style>
