<template>
<div :class="`inline-flex justify-center items-center h-${size} w-${size} relative`" @click="$emit('action')">
    <div v-if="border" :class="`h-${size} w-${size} border text-white rounded-full absolute z-0`" />
    <div v-if="background" :class="[`h-${size} w-${size} rounded-full absolute z-0`, background]" />

    <div :class="[innerClass, 'z-0', `h-${!border ? size : (iconSize ? iconSize : size-5)}`, `w-${!border ? size : (iconSize ? iconSize : size-5)}`]">
                
        <template v-if="name ===  'dots-horizontal'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="none">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M10.000 7.000 A2.000 2.000 0 1 0 14.000 7.000 A2.000 2.000 0 1 0 10.000 7.000 Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
                    <path d="M5.000 7.000 A2.000 2.000 0 1 0 9.000 7.000 A2.000 2.000 0 1 0 5.000 7.000 Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
                    <path d="M0.000 7.000 A2.000 2.000 0 1 0 4.000 7.000 A2.000 2.000 0 1 0 0.000 7.000 Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
                </g>
            </svg>
        </template>
        <template v-if="name ===  'dots-vertical'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="none">
                <g transform="matrix(2,0,0,2,0,0)"><g>
                    <circle cx="12" cy="3.25" r="3.25"></circle>
                    <circle cx="12" cy="12" r="3.25"></circle>
                    <circle cx="12" cy="20.75" r="3.25"></circle>
                </g></g>
            </svg>
        </template>
        <template v-else-if="name ===  'pencil-alt' || name ===  'pencil'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M0.5 13.5L11.5 13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.5,9.975l-3,.53L4.025,7.5,10.732.793a1,1,0,0,1,1.414,0l1.061,1.061a1,1,0,0,1,0,1.414Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'cog'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M5.225,2.251l.438-1.116A1,1,0,0,1,6.594.5h.812a1,1,0,0,1,.931.635l.438,1.116,1.45.838,1.185-.18a1,1,0,0,1,1.016.489l.406.7a1,1,0,0,1-.084,1.124L12,6.163V7.837l.748.937A1,1,0,0,1,12.832,9.9l-.406.7a1,1,0,0,1-1.016.489l-1.185-.18-1.45.838-.438,1.116a1,1,0,0,1-.931.635H6.594a1,1,0,0,1-.931-.635l-.438-1.116-1.45-.838-1.185.18A1,1,0,0,1,1.574,10.6l-.406-.7a1,1,0,0,1,.084-1.124L2,7.837V6.163l-.748-.937A1,1,0,0,1,1.168,4.1l.406-.7A1,1,0,0,1,2.59,2.909l1.185.18ZM5,7A2,2,0,1,0,7,5,2,2,0,0,0,5,7Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'logout'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M9.5,10v2.5a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1V4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.5 7L13.5 7" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12 5.5L13.5 7 12 8.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'plus-circle'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M0.500 7.000 A6.500 6.500 0 1 0 13.500 7.000 A6.500 6.500 0 1 0 0.500 7.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M7 4L7 10" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M4 7L10 7" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'plus'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M0.75 12L23.25 12" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M12 0.75L12 23.25" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'trash'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M11.5 5.5L10.5 13.5 3.5 13.5 2.5 5.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M1 3.5L13 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M4.458,3.208l.027-1.723a1,1,0,0,1,1-.985H8.5a1,1,0,0,1,1,1v2" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'trash2'">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_196:866)">
                    <path d="M12.9998 5H2.99984C2.91143 5 2.82665 5.03512 2.76414 5.09763C2.70162 5.16014 2.6665 5.24493 2.6665 5.33333V14.6667C2.6665 15.0203 2.80698 15.3594 3.05703 15.6095C3.30708 15.8595 3.64622 16 3.99984 16H11.9998C12.3535 16 12.6926 15.8595 12.9426 15.6095C13.1927 15.3594 13.3332 15.0203 13.3332 14.6667V5.33333C13.3332 5.24493 13.2981 5.16014 13.2355 5.09763C13.173 5.03512 13.0882 5 12.9998 5ZM6.83317 13.6667C6.83317 13.7993 6.78049 13.9265 6.68672 14.0202C6.59296 14.114 6.46578 14.1667 6.33317 14.1667C6.20056 14.1667 6.07339 14.114 5.97962 14.0202C5.88585 13.9265 5.83317 13.7993 5.83317 13.6667V7.66667C5.83317 7.53406 5.88585 7.40688 5.97962 7.31311C6.07339 7.21935 6.20056 7.16667 6.33317 7.16667C6.46578 7.16667 6.59296 7.21935 6.68672 7.31311C6.78049 7.40688 6.83317 7.53406 6.83317 7.66667V13.6667ZM10.1665 13.6667C10.1665 13.7993 10.1138 13.9265 10.0201 14.0202C9.92629 14.114 9.79911 14.1667 9.6665 14.1667C9.5339 14.1667 9.40672 14.114 9.31295 14.0202C9.21918 13.9265 9.1665 13.7993 9.1665 13.6667V7.66667C9.1665 7.53406 9.21918 7.40688 9.31295 7.31311C9.40672 7.21935 9.5339 7.16667 9.6665 7.16667C9.79911 7.16667 9.92629 7.21935 10.0201 7.31311C10.1138 7.40688 10.1665 7.53406 10.1665 7.66667V13.6667Z" fill="#928BBA"/>
                    <path d="M14.6665 2.66667H11.4998C11.4556 2.66667 11.4132 2.64911 11.382 2.61785C11.3507 2.5866 11.3332 2.5442 11.3332 2.5V1.66667C11.3332 1.22464 11.1576 0.800716 10.845 0.488155C10.5325 0.175595 10.1085 0 9.6665 0L6.33317 0C5.89114 0 5.46722 0.175595 5.15466 0.488155C4.8421 0.800716 4.6665 1.22464 4.6665 1.66667V2.5C4.6665 2.5442 4.64894 2.5866 4.61769 2.61785C4.58643 2.64911 4.54404 2.66667 4.49984 2.66667H1.33317C1.15636 2.66667 0.98679 2.7369 0.861766 2.86193C0.736742 2.98695 0.666504 3.15652 0.666504 3.33333C0.666504 3.51014 0.736742 3.67971 0.861766 3.80474C0.98679 3.92976 1.15636 4 1.33317 4H14.6665C14.8433 4 15.0129 3.92976 15.1379 3.80474C15.2629 3.67971 15.3332 3.51014 15.3332 3.33333C15.3332 3.15652 15.2629 2.98695 15.1379 2.86193C15.0129 2.7369 14.8433 2.66667 14.6665 2.66667V2.66667ZM5.99984 2.5V1.66667C5.99984 1.57826 6.03496 1.49348 6.09747 1.43096C6.15998 1.36845 6.24476 1.33333 6.33317 1.33333H9.6665C9.75491 1.33333 9.83969 1.36845 9.90221 1.43096C9.96472 1.49348 9.99984 1.57826 9.99984 1.66667V2.5C9.99984 2.5442 9.98228 2.5866 9.95102 2.61785C9.91977 2.64911 9.87737 2.66667 9.83317 2.66667H6.1665C6.1223 2.66667 6.07991 2.64911 6.04865 2.61785C6.0174 2.5866 5.99984 2.5442 5.99984 2.5Z" fill="#928BBA"/>
                </g>
                <defs>
                    <clipPath id="clip0_196:866">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </template>   
        <template v-else-if="name ===  'user-group'">            
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M3.375 7.875 A4.125 4.125 0 1 0 11.625 7.875 A4.125 4.125 0 1 0 3.375 7.875 Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M.75,20.25a6.75,6.75,0,0,1,13.5,0" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M14.251,4.416a4.125,4.125,0,1,1-.006,6.914" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M15,13.667a6.757,6.757,0,0,1,8.25,6.583" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'user'">
            <svg viewBox="0 0 20 20" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'user-circle'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M6.750 9.750 A5.250 5.250 0 1 0 17.250 9.750 A5.250 5.250 0 1 0 6.750 9.750 Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M18.913,20.876a9.746,9.746,0,0,0-13.826,0" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'eye' || name ===  'views'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'arrow-circle-left'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M10 7L4 7" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.5 5.5L4 7 5.5 8.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M0.500 7.000 A6.500 6.500 0 1 0 13.500 7.000 A6.500 6.500 0 1 0 0.500 7.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'arrow-left'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M23.25 12L0.75 12" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M11.25 1.5L0.75 12 11.25 22.5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'arrow-right'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path d="M0.75 12L23.25 12" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M12.75 22.5L23.25 12 12.75 1.5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
            </g>
            </svg>
        </template>
        <template v-else-if="name ===  'duplicate'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M5.5 3.192L5.5 7.808" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M3.192 5.5L7.808 5.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M0.500 0.500 L10.500 0.500 L10.500 10.500 L0.500 10.500 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M3.5,13.5h9a1,1,0,0,0,1-1v-9" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'upload'">
            <svg width="16" height="17" :class="iconClass" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM11.2733 7.44C11.2208 7.55697 11.1356 7.65629 11.028 7.72605C10.9204 7.79582 10.7949 7.83307 10.6667 7.83333H9.16667C9.12247 7.83333 9.08008 7.85089 9.04882 7.88215C9.01756 7.9134 9 7.9558 9 8V12.8333C9 13.0985 8.89465 13.3529 8.70711 13.5404C8.51957 13.728 8.26522 13.8333 8 13.8333C7.73479 13.8333 7.48043 13.728 7.2929 13.5404C7.10536 13.3529 7 13.0985 7 12.8333V8C7 7.9558 6.98244 7.9134 6.95119 7.88215C6.91993 7.85089 6.87754 7.83333 6.83334 7.83333H5.33334C5.20522 7.83317 5.07986 7.7961 4.97227 7.72654C4.86467 7.65699 4.77939 7.55791 4.72664 7.44116C4.67389 7.3244 4.6559 7.19492 4.67482 7.06821C4.69375 6.94149 4.74878 6.82292 4.83334 6.72667L7.5 3.72667C7.56257 3.65573 7.6395 3.59892 7.72571 3.56C7.81192 3.52108 7.90542 3.50096 8 3.50096C8.09459 3.50096 8.18809 3.52108 8.2743 3.56C8.3605 3.59892 8.43744 3.65573 8.5 3.72667L11.1667 6.72667C11.251 6.82281 11.3059 6.94118 11.3248 7.06766C11.3437 7.19414 11.3258 7.32339 11.2733 7.44Z" fill="#1D173F"/>
            </svg>
        </template>
        <template v-else-if="name ===  'view-boards'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V4zM8 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H9a1 1 0 01-1-1V4zM15 3a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V4a1 1 0 00-1-1h-2z" />
            </svg>
        </template>
        <template v-else-if="name ===  'chevron-updown'">
            <svg viewBox="0 0 6 6" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" width="6" height="6">
                <path d="M0.0642915 5.5083C0.107811 5.58552 0.171072 5.64977 0.247597 5.69449C0.324121 5.73921 0.411158 5.76279 0.499792 5.7628L5.36279 5.7628C5.45138 5.76276 5.53836 5.73918 5.61484 5.69448C5.69132 5.64979 5.75455 5.58557 5.79807 5.50841C5.84158 5.43125 5.86382 5.34391 5.86249 5.25534C5.86117 5.16676 5.83634 5.08013 5.79054 5.0043L3.35904 0.986802C3.3145 0.913215 3.25173 0.852364 3.1768 0.810128C3.10187 0.767893 3.01731 0.745703 2.93129 0.745703C2.84528 0.745703 2.76072 0.767893 2.68578 0.810128C2.61085 0.852364 2.54808 0.913215 2.50354 0.986802L0.0720416 5.0043C0.026229 5.0801 0.00137096 5.1667 9.10428e-06 5.25525C-0.00135275 5.34381 0.0208311 5.43113 0.0642915 5.5083Z" fill="#928BBA"/>
            </svg>
            <svg viewBox="0 0 6 6" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" width="6" height="6">
                <path d="M5.79826 0.491698C5.75474 0.414484 5.69148 0.350226 5.61495 0.305506C5.53843 0.260786 5.45139 0.237212 5.36276 0.237198H0.499757C0.411173 0.237241 0.324189 0.260818 0.247709 0.305516C0.171228 0.350215 0.107995 0.41443 0.0644808 0.49159C0.0209667 0.568751 -0.00126672 0.656087 5.57335e-05 0.744662C0.00137819 0.833237 0.026209 0.919871 0.0720074 0.995698L2.50351 5.0132C2.54805 5.08678 2.61082 5.14764 2.68575 5.18987C2.76068 5.23211 2.84524 5.2543 2.93126 5.2543C3.01727 5.2543 3.10183 5.23211 3.17677 5.18987C3.2517 5.14764 3.31447 5.08678 3.35901 5.0132L5.79051 0.995698C5.83632 0.919903 5.86118 0.8333 5.86254 0.744746C5.8639 0.656192 5.84172 0.568865 5.79826 0.491698V0.491698Z" fill="#928BBA"/>
            </svg>
        </template>
        <template v-else-if="name ===  'chevron-down'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M.5,3.854,6.646,10a.5.5,0,0,0,.708,0L13.5,3.854" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'chevron-up'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M.75,17.189,11.47,6.47a.749.749,0,0,1,1.06,0L23.25,17.189" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>          
        </template>
        <template v-else-if="name ===  'chevron-double-left'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M7.543.5,1.4,6.646a.5.5,0,0,0,0,.708L7.543,13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M12.75.5,6.6,6.646a.5.5,0,0,0,0,.708L12.75,13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'chevron-double-right'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M6.457.5,12.6,6.646a.5.5,0,0,1,0,.708L6.457,13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M1.25.5,7.4,6.646a.5.5,0,0,1,0,.708L1.25,13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name === 'chevron-double-up'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path d="M.75,11.689,11.47.97a.749.749,0,0,1,1.06,0L23.25,11.689" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path> 
                <path d="M.75,22.939,11.47,12.22a.749.749,0,0,1,1.06,0L23.25,22.939" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
            </g>
            </svg>
        </template>
        <template v-else-if="name === 'chevron-double-down'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path d="M23.25,12.311,12.53,23.03a.749.749,0,0,1-1.06,0L.75,12.311" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M23.25,1.061,12.53,11.78a.749.749,0,0,1-1.06,0L.75,1.061" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
            </g></svg>
        </template>
        <template v-else-if="name === 'arrow-narrow-left'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
        </template>
        <template v-else-if="name ===  'x'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M12.5 1.5L1.5 12.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M1.5 1.5L12.5 12.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'user-add'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M2.750 2.750 A2.250 2.250 0 1 0 7.250 2.750 A2.250 2.250 0 1 0 2.750 2.750 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M4.5,12.5H.5V11A4.5,4.5,0,0,1,7.013,6.974" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M10.5 7.5L10.5 13.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M7.5 10.5L13.5 10.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'files'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M2.317 2.892 L10.150 2.892 L10.150 13.075 L2.317 13.075 Z" fill="none" stroke-linecap="round" stroke-linejoin="round" transform="translate(-1.232 1.138) rotate(-9.496)"></path>
                    <path d="M3.914 5.989L7.777 5.342" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M4.302 8.306L8.165 7.66" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M4.69 10.624L7.008 10.236" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M5.486.5l6.261.245a.782.782,0,0,1,.752.813l-.31,7.949" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'file'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M22.477,21.75a1.5,1.5,0,0,1-1.5,1.5H3.023a1.5,1.5,0,0,1-1.5-1.5V2.25a1.5,1.5,0,0,1,1.5-1.5h15a1.5,1.5,0,0,1,1.047.426l2.955,2.883a1.5,1.5,0,0,1,.452,1.074Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'image'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'audio'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
            </svg>
        </template>
        <template v-else-if="name ===  'folder'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M23.231,12.435a1,1,0,0,0-.979-1.193H10.424a1,1,0,0,1-.978-.8L9.168,9.051a1,1,0,0,0-.978-.8H1.747A1,1,0,0,0,.769,9.444l2.593,13a1,1,0,0,0,.978.8H20.258a1,1,0,0,0,.978-.8C21.647,20.392,22.744,14.869,23.231,12.435Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M4.5,5.25l.02-3.5a1,1,0,0,1,1-1H18.483a1,1,0,0,1,1,1l.02,6.5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M7.512 3.742L15.75 3.75" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M12 6.75L15.75 6.75" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'task-check'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M16.313,7.68l-5.592,5.592a.82.82,0,0,1-1.158,0L7.688,11.4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M22.371,17.39l-4.992,4.993a3,3,0,0,1-2.122.878H3.75a3,3,0,0,1-3-3V3.761a3,3,0,0,1,3-3h16.5a3,3,0,0,1,3,3V15.269A3,3,0,0,1,22.371,17.39Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M15.75,23.22V18.761a3,3,0,0,1,3-3h4.459" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'graph-stats'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M23.25 12.75L23.25 6 16.5 6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M23.25,6l-7.939,7.939a1.5,1.5,0,0,1-2.122,0l-3.128-3.128a1.5,1.5,0,0,0-2.122,0L.75,18" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'speakerphone'">
            <template v-if="fill">
                <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clip-rule="evenodd" />
                </svg>
            </template>
            <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
            </template>
        </template>
        <template v-else-if="name ===  'photograph'">
            <template>
                <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                </svg>
            </template>
        </template>
        <template v-else-if="name ===  'clipboard-list'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M9,2.5a2,2,0,0,0-4,0H3a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M5 8.923L6.5 9.923 9.115 6" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'chart-bar'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M0.500 7.000 A6.500 6.500 0 1 0 13.500 7.000 A6.500 6.500 0 1 0 0.500 7.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M7 0.5L7 7 11.596 11.596" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'trending-up'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M9.5 3.5L13.5 3.5 13.5 7.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M13.5,3.5,7.854,9.146a.5.5,0,0,1-.708,0L4.854,6.854a.5.5,0,0,0-.708,0L.5,10.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'cash'">            
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path class="a" d="M4.5,7.875a.375.375,0,1,1-.375.375A.375.375,0,0,1,4.5,7.875"></path>
                <path class="a" d="M19.5,15.375a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"></path>
                <rect class="a" x="0.75" y="4.5" width="22.5" height="15" rx="1.5" ry="1.5"></rect>
                <circle class="a" cx="12" cy="12" r="3"></circle>
            </g>
            </svg>
        </template>
        <template v-else-if="name ===  'dollar-sign'">            
            <svg viewBox="0 0 18 18" :class="iconClass" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_182:26)">
                    <path d="M12.375 2.8125H8.1C7.44359 2.8125 6.81406 3.07326 6.34991 3.53741C5.88576 4.00156 5.625 4.63109 5.625 5.2875C5.625 9 12.375 9 12.375 12.7125C12.375 13.3689 12.1142 13.9984 11.6501 14.4626C11.1859 14.9267 10.5564 15.1875 9.9 15.1875H5.625" stroke="#868495" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 2.8125V0.5625" stroke="#868495" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 15.1875V17.4375" stroke="#868495" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_182:26">
                        <rect width="18" height="18" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </template>
        <template v-else-if="name ===  'calendar'">            
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <rect class="a" x="0.752" y="3.75" width="22.5" height="19.5" rx="1.5" ry="1.5"></rect>
                <line class="a" x1="0.752" y1="9.75" x2="23.252" y2="9.75"></line>
                <line class="a" x1="6.752" y1="6" x2="6.752" y2="0.75"></line>
                <line class="a" x1="17.252" y1="6" x2="17.252" y2="0.75"></line>
            </g>
            </svg>
        </template>
        <template v-else-if="name ===  'layers'">            
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path d="M22.91,6.953,12.7,1.672a1.543,1.543,0,0,0-1.416,0L1.076,6.953a.615.615,0,0,0,0,1.094l10.209,5.281a1.543,1.543,0,0,0,1.416,0L22.91,8.047a.616.616,0,0,0,0-1.094Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M.758,12.75l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,12.75" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M.758,17.25l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,17.25" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'needle'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M10 6.499L7 11.499" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M13,11.5a6.5,6.5,0,1,0-12,0Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'bell'">            
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M10,21.75a2.087,2.087,0,0,0,4.005,0" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M12 3L12 0.75" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'flagged'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M9.725,7.672l3.311-2.1a1,1,0,0,0,.171-1.551l-3.2-3.2A1,1,0,0,0,8.465.973L6.305,4.251,1.81,5.289a.6.6,0,0,0-.292,1.016l6.153,6.154a.605.605,0,0,0,1.017-.292Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M4.595 9.382L0.5 13.477" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'check'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'mail'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M0.500 1.750 L13.500 1.750 L13.500 12.250 L0.500 12.250 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M.5,3.015,6.355,7.956a1,1,0,0,0,1.29,0L13.5,3.015" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name === 'link'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(2,0,0,2,0,0)">
                <path d="M6.75 17.249L17.25 6.749" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M7.735,12.021a4.472,4.472,0,0,0-3.417,1.3l-2.25,2.25a4.5,4.5,0,0,0,6.364,6.364l2.25-2.25a4.472,4.472,0,0,0,1.3-3.417" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                <path d="M16.265,11.976a4.473,4.473,0,0,0,3.417-1.3l2.25-2.25a4.5,4.5,0,0,0-6.364-6.364l-2.25,2.25a4.475,4.475,0,0,0-1.295,3.417" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'loader'">
            <svg class="h-full w-full animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </template>
        <template v-else-if="name ===  'refresh'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'export'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'grid'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'tiles'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
        </template>
        <template v-else-if="name ===  'reach'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
        </template>
        <template v-else-if="name ===  'location'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'locked'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'facebook'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 24 24" fill="currentColor" >
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'instagram'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 24 24" fill="currentColor" >
                <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'twitter'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 24 24" fill="currentColor" >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
        </template>
        <template v-else-if="name ===  'tiktok'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 2859 3333" fill="currentColor" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"/>
            </svg>
        </template>
        <template v-else-if="name ===  'youtube'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 500 500" fill="currentColor" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M260.4,449c-57.1-1.8-111.4-3.2-165.7-5.3c-11.7-0.5-23.6-2.3-35-5c-21.4-5-36.2-17.9-43.8-39c-6.1-17-8.3-34.5-9.9-52.3   C2.5,305.6,2.5,263.8,4.2,222c1-23.6,1.6-47.4,7.9-70.3c3.8-13.7,8.4-27.1,19.5-37c11.7-10.5,25.4-16.8,41-17.5   c42.8-2.1,85.5-4.7,128.3-5.1c57.6-0.6,115.3,0.2,172.9,1.3c24.9,0.5,50,1.8,74.7,5c22.6,3,39.5,15.6,48.5,37.6   c6.9,16.9,9.5,34.6,11,52.6c3.9,45.1,4,90.2,1.8,135.3c-1.1,22.9-2.2,45.9-8.7,68.2c-7.4,25.6-23.1,42.5-49.3,48.3   c-10.2,2.2-20.8,3-31.2,3.4C366.2,445.7,311.9,447.4,260.4,449z M205.1,335.3c45.6-23.6,90.7-47,136.7-70.9   c-45.9-24-91-47.5-136.7-71.4C205.1,240.7,205.1,287.6,205.1,335.3z"/>
            </svg>
        </template>
        <template v-else-if="name === 'pinterest'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 97.672 97.672" fill="currentColor" x="0px" y="0px" width="97.672px" height="97.672px" style="enable-background:new 0 0 97.672 97.672;" xml:space="preserve">
                <path d="M51.125,0C24.469,0,11.029,19.11,11.029,35.047c0,9.649,3.653,18.232,11.487,21.432c1.286,0.525,2.438,0.019,2.812-1.403 c0.258-0.985,0.871-3.468,1.144-4.503c0.376-1.407,0.229-1.9-0.807-3.126c-2.259-2.665-3.703-6.115-3.703-11.002 c0-14.178,10.608-26.87,27.624-26.87c15.064,0,23.342,9.206,23.342,21.5c0,16.176-7.159,29.828-17.786,29.828 c-5.87,0-10.262-4.854-8.854-10.807c1.686-7.107,4.951-14.778,4.951-19.907c0-4.592-2.463-8.423-7.565-8.423 c-6,0-10.819,6.207-10.819,14.521c0,5.296,1.789,8.878,1.789,8.878s-6.141,26.015-7.215,30.571 c-2.145,9.072-0.322,20.195-0.168,21.318c0.089,0.666,0.944,0.824,1.332,0.322c0.555-0.723,7.7-9.544,10.129-18.359 c0.687-2.496,3.944-15.42,3.944-15.42c1.95,3.717,7.647,6.992,13.706,6.992c18.034,0,30.271-16.441,30.271-38.45 C86.644,15.498,72.547,0,51.125,0z"/>
            </svg>
        </template>
        <template v-else-if="name ===  'feedback'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor" >
                <path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd" />
            </svg>
        </template>        
        <template v-else-if="name ===  'x-circle'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'minus-circle'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'minus'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M0.75 12.038L23.25 12.038" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'minus-sm'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
            </svg>
        </template>
        <template v-else-if="name ===  'check-circle'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </template>
        <template v-else-if="name ===  'exclamation'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
        </template>
        <template v-else-if="name ===  'exclamation-circle'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </template>
        <template v-else-if="name ===  'search'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(2,0,0,2,0,0)">
                    <path d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="translate(-3.056 4.62) rotate(-23.025)"></path>
                    <path d="M16.221 16.22L23.25 23.25" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'backspace'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'heart'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'thumb-up'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                </svg>
        </template>
        <template v-else-if="name ===  'annotation'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'sort-down'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                    <path d="M10 6L7 9 4 6" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M0.500 7.000 A6.500 6.500 0 1 0 13.500 7.000 A6.500 6.500 0 1 0 0.500 7.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>
        </template>
        <template v-else-if="name ===  'sort-up'">
            <svg viewBox="0 0 48 48" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
            <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
                <path d="M4 7.999L7 5 10 8.001" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M0.500 7.000 A6.500 6.500 0 1 0 13.500 7.000 A6.500 6.500 0 1 0 0.500 7.000 Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
            </g></svg>
        </template>
        <template v-else-if="name ===  'comments'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'like'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'youtube-like'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
        </template>
        <template v-else-if="name ===  'dislike'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
            </svg>
        </template>
        <template v-else-if="name ===  'share'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
            </svg>
        </template>
        <template v-else-if="name ===  'close'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'cube'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
            </svg>
        </template>
        <template v-else-if="name ===  'world'">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd" />
            </svg>
        </template>
        <template v-else-if="name ===  'camera'" fill="currentColor">
            <svg xmlns="http://www.w3.org/2000/svg" :class="iconClass" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
        </template>

        <template v-else-if="name ===  'github'" fill="currentColor">
            <svg :class="iconClass" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0.148499C2.685 0.148499 0 2.835 0 6.1485C0 8.8 1.719 11.0485 4.1025 11.841C4.4025 11.8975 4.5125 11.712 4.5125 11.5525C4.5125 11.41 4.5075 11.0325 4.505 10.5325C2.836 10.8945 2.484 9.7275 2.484 9.7275C2.211 9.035 1.8165 8.85 1.8165 8.85C1.273 8.478 1.8585 8.4855 1.8585 8.4855C2.461 8.5275 2.7775 9.1035 2.7775 9.1035C3.3125 10.021 4.182 9.756 4.525 9.6025C4.579 9.2145 4.7335 8.95 4.905 8.8C3.5725 8.65 2.172 8.134 2.172 5.835C2.172 5.18 2.4045 4.645 2.7895 4.225C2.722 4.0735 2.5195 3.4635 2.842 2.637C2.842 2.637 3.3445 2.476 4.492 3.252C4.972 3.1185 5.482 3.0525 5.992 3.0495C6.502 3.0525 7.012 3.1185 7.492 3.252C8.632 2.476 9.1345 2.637 9.1345 2.637C9.457 3.4635 9.2545 4.0735 9.1945 4.225C9.577 4.645 9.8095 5.18 9.8095 5.835C9.8095 8.14 8.407 8.6475 7.072 8.795C7.282 8.975 7.477 9.343 7.477 9.905C7.477 10.708 7.4695 11.353 7.4695 11.548C7.4695 11.7055 7.5745 11.893 7.882 11.833C10.2825 11.046 12 8.796 12 6.1485C12 2.835 9.3135 0.148499 6 0.148499" fill="#1A1E44"/>
            </svg>
        </template>

        <template v-else-if="name ===  'voteUp'" fill="currentColor">
            <svg :class="iconClass" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.587 0H2.413C1.77362 0.000792562 1.16063 0.254999 0.708333 0.70692C0.256037 1.15884 0.00132273 1.77162 0 2.411L0 9.585C0.000264436 10.2251 0.254512 10.8389 0.706922 11.2917C1.15933 11.7444 1.77293 11.9992 2.413 12H9.587C10.2271 11.9992 10.8407 11.7444 11.2931 11.2917C11.7455 10.8389 11.9997 10.2251 12 9.585V2.411C11.9987 1.77162 11.744 1.15884 11.2917 0.70692C10.8394 0.254999 10.2264 0.000792562 9.587 0V0ZM11 9.585C11 9.95994 10.8512 10.3195 10.5863 10.5848C10.3213 10.8502 9.96194 10.9995 9.587 11H2.413C2.03802 10.9996 1.67855 10.8503 1.41359 10.585C1.14863 10.3197 0.999867 9.95998 1 9.585V2.411C1.00079 2.03668 1.14998 1.67794 1.41486 1.41344C1.67973 1.14894 2.03868 1.00026 2.413 1H9.587C9.96128 1.0004 10.3202 1.14912 10.585 1.41359C10.8498 1.67806 10.9991 2.03672 11 2.411V9.585Z" fill="currentColor"/>
                <path d="M6.18812 2.46306C6.16465 2.43621 6.13571 2.41468 6.10324 2.39994C6.07077 2.38519 6.03553 2.37756 5.99987 2.37756C5.9642 2.37756 5.92896 2.38519 5.89649 2.39994C5.86402 2.41468 5.83508 2.43621 5.81162 2.46306L2.86162 5.83356C2.82937 5.86967 2.8083 5.91437 2.80098 5.96223C2.79365 6.01008 2.80037 6.05904 2.82034 6.10315C2.8403 6.14725 2.87264 6.18462 2.91343 6.2107C2.95422 6.23677 3.0017 6.25045 3.05012 6.25006H4.62461C4.65777 6.25006 4.68956 6.26323 4.713 6.28667C4.73645 6.31011 4.74961 6.34191 4.74961 6.37506V9.50006C4.74961 9.56636 4.77595 9.62995 4.82284 9.67684C4.86972 9.72372 4.93331 9.75006 4.99961 9.75006H6.99961C7.06592 9.75006 7.12951 9.72372 7.17639 9.67684C7.22328 9.62995 7.24961 9.56636 7.24961 9.50006V6.37506C7.24961 6.34191 7.26278 6.31011 7.28623 6.28667C7.30967 6.26323 7.34146 6.25006 7.37461 6.25006H8.95011C8.99811 6.25001 9.04507 6.23615 9.0854 6.21013C9.12573 6.18411 9.15771 6.14703 9.17754 6.10332C9.19736 6.05962 9.20418 6.01113 9.19719 5.96364C9.1902 5.91616 9.16969 5.8717 9.13811 5.83556L6.18812 2.46306Z" fill="currentColor"/>
            </svg>

        </template>

        <template v-else-if="name ===  'archive'" fill="currentColor">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.75 0.25H1.25C0.984784 0.25 0.73043 0.355357 0.542893 0.542893C0.355357 0.73043 0.25 0.984784 0.25 1.25V2.75C0.25 2.88261 0.302678 3.00979 0.396447 3.10355C0.490215 3.19732 0.617392 3.25 0.75 3.25H11.25C11.3826 3.25 11.5098 3.19732 11.6036 3.10355C11.6973 3.00979 11.75 2.88261 11.75 2.75V1.25C11.75 0.984784 11.6446 0.73043 11.4571 0.542893C11.2696 0.355357 11.0152 0.25 10.75 0.25Z" fill="currentColor"/>
                <path d="M10.75 4H1.25C1.11739 4 0.990215 4.05268 0.896447 4.14645C0.802678 4.24021 0.75 4.36739 0.75 4.5V8.75C0.75 9.01522 0.855357 9.26957 1.04289 9.45711C1.23043 9.64464 1.48478 9.75 1.75 9.75H10.25C10.5152 9.75 10.7696 9.64464 10.9571 9.45711C11.1446 9.26957 11.25 9.01522 11.25 8.75V4.5C11.25 4.36739 11.1973 4.24021 11.1036 4.14645C11.0098 4.05268 10.8826 4 10.75 4ZM7.625 5.75C7.62474 5.91568 7.5588 6.0745 7.44165 6.19165C7.3245 6.3088 7.16568 6.37474 7 6.375H5C4.83424 6.375 4.67527 6.30915 4.55806 6.19194C4.44085 6.07473 4.375 5.91576 4.375 5.75C4.375 5.58424 4.44085 5.42527 4.55806 5.30806C4.67527 5.19085 4.83424 5.125 5 5.125H7C7.16568 5.12526 7.3245 5.1912 7.44165 5.30835C7.5588 5.4255 7.62474 5.58432 7.625 5.75Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'edit'" fill="currentColor">
            <svg width="8" height="8" viewBox="0 0 8 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.39544 1.17034C5.36361 1.14019 5.32144 1.12338 5.27761 1.12338C5.23377 1.12338 5.1916 1.14019 5.15977 1.17034L1.35544 4.97467C1.32419 5.00592 1.30664 5.04831 1.30664 5.0925C1.30664 5.1367 1.32419 5.17908 1.35544 5.21034L2.78211 6.637C2.81336 6.66825 2.85574 6.6858 2.89994 6.6858C2.94413 6.6858 2.98652 6.66825 3.01777 6.637L6.82211 2.83334C6.85335 2.80208 6.8709 2.7597 6.8709 2.7155C6.8709 2.67131 6.85335 2.62892 6.82211 2.59767L5.39544 1.17034Z" fill="currentColor"/>
                <path d="M0.999933 5.57C0.97974 5.54992 0.954742 5.53536 0.927323 5.52769C0.899903 5.52002 0.870976 5.5195 0.8433 5.52619C0.815624 5.53287 0.790122 5.54654 0.769226 5.56588C0.74833 5.58522 0.732736 5.60959 0.723933 5.63666L0.0105996 7.77666C0.000756654 7.80602 -0.000714732 7.83753 0.00635042 7.86768C0.0134156 7.89782 0.0287379 7.9254 0.0505996 7.94733C0.0726553 7.96899 0.10022 7.9842 0.130305 7.99131C0.16039 7.99842 0.191847 7.99716 0.221266 7.98766L2.36127 7.27466C2.38841 7.26589 2.41284 7.25029 2.43223 7.22937C2.45162 7.20845 2.46531 7.1829 2.472 7.15517C2.47869 7.12744 2.47815 7.09846 2.47044 7.071C2.46272 7.04354 2.44809 7.01852 2.42793 6.99833L0.999933 5.57Z" fill="currentColor"/>
                <path d="M7.65583 0.338995C7.43182 0.125278 7.1341 0.00604248 6.8245 0.00604248C6.51489 0.00604248 6.21717 0.125278 5.99316 0.338995L5.75583 0.576662C5.72458 0.607917 5.70703 0.650301 5.70703 0.694495C5.70703 0.738689 5.72458 0.781074 5.75583 0.812329L7.18216 2.239C7.21342 2.27024 7.2558 2.28779 7.3 2.28779C7.34419 2.28779 7.38657 2.27024 7.41783 2.239L7.65583 2C7.87556 1.77945 7.99894 1.48082 7.99894 1.1695C7.99894 0.858173 7.87556 0.559538 7.65583 0.338995Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'arrow-left-bold'" fill="currentColor">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8825 0.289505L0.4 5.11601C0.159316 5.34923 0.0163205 5.66526 -2.57637e-07 6.00001C0.0165186 6.33471 0.159483 6.65066 0.4 6.88401L5.8825 11.7105C5.95186 11.7735 6.03307 11.8221 6.12139 11.8534C6.2097 11.8847 6.30337 11.8982 6.39693 11.8929C6.49049 11.8877 6.58208 11.8639 6.66636 11.823C6.75064 11.782 6.82593 11.7247 6.88784 11.6543C6.94976 11.584 6.99706 11.5021 7.027 11.4133C7.05694 11.3245 7.06891 11.2306 7.06223 11.1371C7.05554 11.0437 7.03033 10.9525 6.98806 10.8688C6.94578 10.7852 6.8873 10.7108 6.816 10.65L2.583 6.92501C2.56403 6.90817 2.55063 6.88597 2.54457 6.86134C2.53852 6.83671 2.5401 6.81083 2.54911 6.78712C2.55811 6.76341 2.57412 6.743 2.595 6.7286C2.61588 6.7142 2.64064 6.7065 2.666 6.70651L11.2935 6.70651C11.4809 6.70651 11.6606 6.63207 11.7931 6.49958C11.9256 6.36708 12 6.18738 12 6.00001C12 5.81263 11.9256 5.63293 11.7931 5.50043C11.6606 5.36794 11.4809 5.2935 11.2935 5.2935L2.666 5.29351C2.64057 5.29353 2.61574 5.28579 2.59482 5.27133C2.5739 5.25687 2.55789 5.23637 2.54892 5.21257C2.53995 5.18877 2.53846 5.16281 2.54464 5.13814C2.55082 5.11347 2.56438 5.09127 2.5835 5.07451L6.816 1.35C6.8873 1.28919 6.94578 1.21481 6.98806 1.13118C7.03033 1.04755 7.05554 0.956344 7.06223 0.862877C7.06891 0.769408 7.05694 0.675543 7.027 0.586748C6.99706 0.497951 6.94976 0.415999 6.88784 0.345661C6.82593 0.275322 6.75064 0.218003 6.66636 0.177042C6.58208 0.13608 6.49049 0.112293 6.39693 0.107067C6.30337 0.101841 6.2097 0.115279 6.12139 0.1466C6.03307 0.177921 5.95186 0.226498 5.8825 0.289505Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'reddit'" fill="currentColor">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 11.889c0-.729-.596-1.323-1.329-1.323-.358 0-.681.143-.92.373-.905-.595-2.13-.975-3.485-1.023l.742-2.334 2.008.471-.003.029c0 .596.487 1.082 1.087 1.082.599 0 1.086-.485 1.086-1.082s-.488-1.082-1.087-1.082c-.46 0-.852.287-1.01.69l-2.164-.507c-.094-.023-.191.032-.22.124l-.827 2.603c-1.419.017-2.705.399-3.65 1.012-.237-.219-.552-.356-.9-.356-.732.001-1.328.594-1.328 1.323 0 .485.267.905.659 1.136-.026.141-.043.283-.043.429-.001 1.955 2.404 3.546 5.359 3.546 2.956 0 5.36-1.591 5.36-3.546 0-.137-.015-.272-.038-.405.416-.224.703-.657.703-1.16zm-8.612.908c0-.434.355-.788.791-.788.436 0 .79.353.79.788 0 .434-.355.787-.79.787-.436.001-.791-.352-.791-.787zm4.53 2.335c-.398.396-1.024.589-1.912.589l-.007-.001-.007.001c-.888 0-1.514-.193-1.912-.589-.073-.072-.073-.19 0-.262.072-.072.191-.072.263 0 .325.323.864.481 1.649.481l.007.001.007-.001c.784 0 1.324-.157 1.649-.481.073-.072.19-.072.263 0 .073.072.073.19 0 .262zm-.094-1.547c-.436 0-.79-.353-.79-.787 0-.434.355-.788.79-.788.436 0 .79.353.79.788 0 .434-.354.787-.79.787z"/>
            </svg>
        </template>

        <template v-else-if="name ===  'previous-page'" fill="currentColor">
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.16016 1.41L3.58016 6L8.16016 10.59L6.75016 12L0.750156 6L6.75016 0L8.16016 1.41Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'next-page'" fill="currentColor">
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.839844 1.41L5.41984 6L0.839844 10.59L2.24984 12L8.24984 6L2.24984 0L0.839844 1.41Z" fill="currentColor"/>
            </svg>

        </template>

        <template v-else-if="name ===  'link-up-right'" fill="currentColor">
            <svg width="8" height="7" viewBox="0 0 8 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33317 1.33333L5.26417 1.33333C5.28067 1.3333 5.29682 1.33818 5.31055 1.34733C5.32428 1.35649 5.33498 1.36951 5.3413 1.38476C5.34762 1.4 5.34927 1.41678 5.34604 1.43297C5.34282 1.44915 5.33486 1.46401 5.32317 1.47567L0.979837 5.81333C0.885995 5.90704 0.833221 6.03419 0.833128 6.16681C0.833034 6.29943 0.885627 6.42666 0.979337 6.5205C1.02574 6.56697 1.08084 6.60384 1.14149 6.62901C1.20214 6.65418 1.26715 6.66716 1.33282 6.66721C1.46544 6.6673 1.59266 6.61471 1.6865 6.521L6.02417 2.18767C6.03583 2.17598 6.05069 2.16802 6.06687 2.16479C6.08306 2.16157 6.09984 2.16322 6.11508 2.16954C6.13033 2.17586 6.14335 2.18656 6.15251 2.20029C6.16166 2.21402 6.16653 2.23016 6.1665 2.24667L6.1665 6.16667C6.1665 6.29928 6.21918 6.42645 6.31295 6.52022C6.40672 6.61399 6.5339 6.66667 6.6665 6.66667C6.79911 6.66667 6.92629 6.61399 7.02006 6.52022C7.11383 6.42645 7.1665 6.29928 7.1665 6.16667L7.1665 1.16667C7.1665 0.945654 7.07871 0.733692 6.92243 0.577412C6.76615 0.421132 6.55418 0.333334 6.33317 0.333334L1.33317 0.333334C1.20056 0.333334 1.07339 0.386012 0.979618 0.479781C0.88585 0.573549 0.833171 0.700725 0.833171 0.833334C0.833171 0.965942 0.885849 1.09312 0.979618 1.18689C1.07339 1.28065 1.20056 1.33333 1.33317 1.33333Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'gem'" fill="currentColor">
            <svg width="16" height="10" viewBox="0 0 16 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.2513 2.37428L9.30348 8.32205L8.22998 6.03965L11.1458 3.11896L15.2513 2.37428Z" fill="currentColor"/>
                <path d="M7.62573 10L0 2.37428L4.10542 3.11896L9.30368 8.32205L7.62573 10Z" fill="currentColor"/>
                <path d="M15.2515 2.37427H0L1.75048 0H13.501L15.2515 2.37427Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'tag'" fill="currentColor">        
            <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.691141 5.692L6.58014 11.4765L6.58864 11.4845C6.77712 11.6675 7.03007 11.769 7.29279 11.7671C7.55552 11.7651 7.80692 11.6598 7.99264 11.474L11.5251 7.9425C11.6186 7.84913 11.6925 7.73818 11.7428 7.61606C11.7931 7.49394 11.8188 7.36307 11.8182 7.231C11.8176 7.09893 11.7909 6.96827 11.7396 6.84658C11.6883 6.72489 11.6133 6.61457 11.5191 6.522L5.65014 0.749998C5.64114 0.740498 5.63264 0.731498 5.62314 0.722998C5.29533 0.423044 4.87166 0.249219 4.42764 0.232498H1.18164C0.916424 0.232498 0.66207 0.337855 0.474534 0.525391C0.286997 0.712928 0.181641 0.967282 0.181641 1.2325L0.185141 4.48C0.203674 4.93146 0.383162 5.36138 0.691141 5.692ZM2.43164 3.2325C2.43164 3.08416 2.47563 2.93916 2.55804 2.81582C2.64045 2.69248 2.75758 2.59635 2.89463 2.53959C3.03167 2.48282 3.18247 2.46797 3.32796 2.49691C3.47344 2.52585 3.60708 2.59728 3.71197 2.70217C3.81686 2.80706 3.88829 2.94069 3.91723 3.08618C3.94617 3.23167 3.93132 3.38247 3.87455 3.51951C3.81778 3.65656 3.72165 3.77369 3.59832 3.8561C3.47498 3.93851 3.32998 3.9825 3.18164 3.9825C2.98273 3.9825 2.79196 3.90348 2.65131 3.76283C2.51066 3.62218 2.43164 3.43141 2.43164 3.2325Z" fill="currentColor"/>
            </svg>
        </template>

        <template v-else-if="name ===  'link-new'" fill="currentColor">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.22919 9.187L3.86069 10.555C3.59527 10.8086 3.2423 10.9501 2.87519 10.9501C2.50808 10.9501 2.15511 10.8086 1.88969 10.555L1.44369 10.1095C1.18242 9.84818 1.03565 9.49378 1.03565 9.12425C1.03565 8.75472 1.18242 8.40032 1.44369 8.139L4.34369 5.239C4.60516 4.97782 4.95962 4.83112 5.32919 4.83112C5.69876 4.83112 6.05322 4.97782 6.31469 5.239L6.76119 5.685C6.85521 5.77902 6.98273 5.83184 7.11569 5.83184C7.24865 5.83184 7.37617 5.77902 7.47019 5.685C7.56421 5.59098 7.61703 5.46346 7.61703 5.3305C7.61703 5.19754 7.56421 5.07002 7.47019 4.976L7.02369 4.53C6.57454 4.08144 5.96571 3.8295 5.33094 3.8295C4.69616 3.8295 4.08734 4.08144 3.63819 4.53L0.738189 7.43C0.51592 7.65226 0.339606 7.91612 0.219315 8.20652C0.0990228 8.49692 0.0371094 8.80817 0.0371094 9.1225C0.0371094 9.43683 0.0990228 9.74808 0.219315 10.0385C0.339606 10.3289 0.51592 10.5927 0.738189 10.815L1.18419 11.2605C1.40639 11.483 1.67026 11.6594 1.96072 11.7799C2.25117 11.9003 2.56251 11.9622 2.87694 11.9622C3.19136 11.9622 3.50271 11.9003 3.79316 11.7799C4.08362 11.6594 4.34749 11.483 4.56969 11.2605L5.93769 9.893C5.98414 9.84658 6.021 9.79146 6.04615 9.73079C6.07131 9.67013 6.08427 9.6051 6.08429 9.53943C6.08431 9.47375 6.0714 9.40872 6.04629 9.34803C6.02118 9.28735 5.98436 9.23221 5.93794 9.18575C5.89152 9.1393 5.8364 9.10244 5.77573 9.07728C5.71507 9.05213 5.65004 9.03917 5.58437 9.03915C5.51869 9.03913 5.45366 9.05204 5.39297 9.07715C5.33229 9.10226 5.27714 9.13908 5.23069 9.1855L5.22919 9.187Z" fill="currentColor"/>
                <path d="M11.2628 1.18149L10.8168 0.735494C10.3671 0.288187 9.75861 0.0370941 9.12432 0.0370941C8.49003 0.0370941 7.88153 0.288187 7.43182 0.735494L5.97932 2.18549C5.88824 2.27979 5.83784 2.4061 5.83898 2.53719C5.84012 2.66829 5.89271 2.7937 5.98541 2.8864C6.07812 2.97911 6.20352 3.03169 6.33462 3.03283C6.46572 3.03397 6.59202 2.98357 6.68632 2.89249L8.13632 1.44249C8.39784 1.18159 8.75216 1.03507 9.12157 1.03507C9.49098 1.03507 9.8453 1.18159 10.1068 1.44249L10.5533 1.88899C10.6828 2.01836 10.7856 2.17199 10.8557 2.34109C10.9257 2.51019 10.9618 2.69144 10.9618 2.87449C10.9618 3.05754 10.9257 3.2388 10.8557 3.4079C10.7856 3.577 10.6828 3.73062 10.5533 3.85999L7.65332 6.75999C7.52423 6.88987 7.37066 6.99286 7.2015 7.06298C7.03233 7.1331 6.85094 7.16896 6.66782 7.16849C6.48489 7.16898 6.30369 7.13316 6.13469 7.06313C5.9657 6.99309 5.81228 6.89023 5.68332 6.76049C5.63753 6.71159 5.58237 6.67239 5.52113 6.64522C5.45989 6.61805 5.39381 6.60346 5.32683 6.60233C5.25984 6.6012 5.19331 6.61353 5.13118 6.63862C5.06906 6.6637 5.01261 6.70101 4.96518 6.74833C4.91776 6.79565 4.88033 6.85203 4.85511 6.9141C4.8299 6.97617 4.81742 7.04268 4.81841 7.10966C4.81941 7.17665 4.83385 7.24276 4.86089 7.30406C4.88793 7.36536 4.92702 7.4206 4.97582 7.46649C5.19751 7.68931 5.46122 7.86591 5.75166 7.98607C6.04211 8.10623 6.35351 8.16756 6.66782 8.16649C6.98222 8.16759 7.2937 8.10629 7.58423 7.98613C7.87476 7.86597 8.13855 7.68935 8.36032 7.46649L11.2603 4.56649C11.4827 4.34426 11.6592 4.08038 11.7795 3.78993C11.8999 3.49947 11.9619 3.18815 11.9619 2.87374C11.9619 2.55934 11.8999 2.24801 11.7795 1.95756C11.6592 1.66711 11.4827 1.40323 11.2603 1.18099L11.2628 1.18149Z" fill="currentColor"/>
            </svg>
        </template>




        <template v-else-if="defaultIcon">
            <base-icon :name="defaultIcon"/>
        </template>
    </div>
</div>
</template>
<script>
    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            defaultIcon: {
                type: String
            },
            size: {
                type: Number,
                default: 5
            },
            iconSize: {
                type: Number,
            },
            fill: {
                type: Boolean,
                default: true
            },
            wrapperClass: {
                type: String
            },
            iconClass: {
                type: String,
                default: 'h-full w-full',
            },
            border: {
                type: Boolean,
                default: false
            },
            background: {
                type: String,
            }
        },
        computed: {
            innerClass() {
                if (this.wrapperClass) {
                    return this.wrapperClass;
                }
                return 'flex justify-center items-center';
            }
        }
    }
</script>