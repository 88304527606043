<template>

    <div class="">
        <div class="pt-5 bg-white py-20 pb-4 p-6">
            <a v-if="this.$route.name != 'entity'" class="text-light-purple font-bold cursor-pointer" v-on:click="prevPage()">
                <base-icon style="top: 1px" class="relative text-light-purple" :name="'arrow-left-bold'"></base-icon>
                <small>Back to <span class="capitalize">{{$route.name}}</span></small>
            </a>

            <div class="mt-10">
                <div class="flex">
                    <div class="w-1/2">

                        <span v-if="typeof tags != 'undefined' && tags.length > 0"><small v-for="(tag, the_key) of tags" :key="the_key" class="dark tag-bubble mr-1"><span v-if="tag && tag.name">{{tag.name}}</span></small></span>
                        
                        <h1 class="entity-name mt-5 font-bold">{{entity.name}}</h1>
                        <base-icon v-if="!description_edit" :iconSize="10" class="cursor-pointer mt-1 -ml-1 text-grey" :name="'edit'"></base-icon>
                        <a style="font-size: 10px" class="cursor-pointer mt-1 text-sm text-grey uppercase" v-on:click="description_edit = true"><span v-if="!description_edit"><span v-if="!entity.description">add</span><span v-else>edit</span> description</span><span v-else>editing...</span></a>
                        <div class=""><small v-if="!entity.description && !description_edit" class="italic">There is no description...</small></div>
                        <textarea v-model="activeDescription" style="height: 200px" class="p-2 border-solid min-w-full" v-if="description_edit"></textarea>
                        <p v-if="entity.description && !description_edit" style="font-size: 13px" class="mt-1 text-purple">{{entity.description}}</p>
                        <button v-if="description_edit" v-on:click="saveDescription()" style="height: 40px" type="button" class="bg-light-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-white font-bold">Save</span></button>
                        <button v-if="description_edit" v-on:click="description_edit = false;" style="height: 40px" type="button" class="text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-black font-bold">Cancel</span></button>
                        

                    </div>

                    <div class="w-2/3">
                        <a target="_blank" v-for="(network_object, the_key) of networks" :key="the_key" :href="produceLink(network_object)"><small class="float-right dark tag-bubble mr-1"><base-icon class="" :name="network_object.network.name.split('-')[0]"></base-icon><span style="bottom: 4.5px" class="relative ml-2">{{network_object.network.name.split('-')[0]}}</span></small></a>
                        <a v-if="entity.website" target="_blank" :href="clean_url(entity.website)"><small class="float-right dark tag-bubble mr-1"><base-icon class="" :name="'link'"></base-icon><span style="bottom: 4.5px" class="relative ml-2">Website</span></small></a>
                        

                    </div>
                </div>
            </div>
            <div class="mt-12">
                <button class="bg-green font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" v-on:click="openAddTags(entity)"><span class="text-white font-bold">Add Tags</span></button>
                <button class="bg-medium-orange font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" v-on:click="$emit('removeProject',entity)">
                    <span class="text-white font-bold">{{trash_button_text}}</span>
                </button>
                
                <button @mouseleave="suggestButtonHover(entity, false)" @mouseover="suggestButtonHover(entity, true)" v-on:click="suggestGem(entity)" type="button" :class="[{ 'suggested' : ( suggest_button_text == suggested_text )}, button_class]" class="bg-button-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    <span v-if="entity.status == 10" class="text-white font-bold">{{suggest_button_text}}</span>
                    <span v-if="entity.status != 10" class="text-white font-bold">Suggest as potential gem</span>
                </button>

                <button v-if="!entity.stats || !entity.stats.cmc" class="ml-2 bg-button-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" v-on:click="openEnrichment(entity)"><span class="text-white font-bold">Add CMC Slug</span></button>

            </div>
            
        </div>

        <div style="margin-bottom: 100px;" class="pt-4 bg-white py-2 p-6 bg-grey">
            <div>
                <div class="flex justify-between">
                    <h1 class="entity-name font-bold">Discussion</h1>
                </div>
                <div class="flex">
                    

                    <div class="w-1/2">

                        <comment class="w-2/3" :object="entity" :type_id="21"></comment>

                    </div>
                </div>
            </div>

        </div>

        
    </div>

</template>
<script>

import BaseIcon from '../components/BaseIcon';
import Comment from '../components/Comment';

export default {
    props: ['entity'],
    components: {
        BaseIcon,
        Comment

    },
    data() {
        return {
            networks: [],
            suggested_text: "Suggested as a potential gem",
            unsuggest_text: "Unsuggest as a potential gem",
            suggest_text: "Suggest as a potential gem",
            trash_button_text: "Archive",
            button_class: this.entity.button_class,
            suggest_button_text: this.entity.suggest_button_text,
            description_edit: false,
            activeDescription: ""
        }
    },
    mounted(){
        this.getNetworks();
        this.addButtonText();
        this.trashButtonText();

        this.activeDescription = this.entity.description;
    },
    computed: {
        tags: function() {
            var tags = [];
            if ( this.entity.tag_objects ) {
                for ( var tag_object of this.entity.tag_objects ) {
                    tags.push(tag_object.tag);
                }

                return tags;
            } else {
                return [];
            }   

        }
    },
    methods: {

        async saveDescription() {

            const { data } = await this.$http.post('/api/entities/entity/save-description', { description: this.activeDescription, entity_id: this.entity.id });

            if ( data.success ) {
                this.entity.description = this.activeDescription;
                this.activeDescription = "";
                this.description_edit = false;
                this.mixpanel.track("Saved Description", { description: this.activeDescription, entity: this.entity });
            }

        },

        clean_url(url) {
            return ( url.indexOf('http') >= 0 ) ? url : "http://" + url;
        },

        openEnrichment(entity) {
            this.$emit('openEnrichment', entity);

        },

        openAddTags(entity) {
            this.addTagsModalVisible = true;
            this.activeEntity = entity;
            this.$emit('openAddTags', entity);

        },

        async vote(object) {

            var post_data = {
                "object_id": object.id,
                "user_id": this.$store.getters.me.id,
                "type_id": 21
            };

            const { data } = await this.$http.post(`/users/vote/new`, post_data);

            if ( data.action == "delete" ) {
                this.mixpanel.track("Unvoted", object);
                for (let item in object.votes) {
                    if ( object.votes[item].id == data.data.id ) {
                        object.votes.splice(item, 1);
                        object['voted'] = false;
                    }
                }

            } else {
                this.mixpanel.track("Voted", object);
                object.votes.push(data.data);
                this.$set(object, "votes", object.votes);
                object.voted = true;
            }


        },

        addButtonText() {

            this.suggest_button_text = ( this.entity.status == 10 ) ? this.suggested_text : this.suggest_text;

        },

        trashButtonText() {

            this.trash_button_text = ( this.entity.status != 0 ) ? "Archive" : "Unarchive";

        },

        suggestButtonHover(entity, hovering) {
            if ( entity.status == 10 ) {
                if ( hovering ) {
                    this.suggest_button_text = this.unsuggest_text;
                    this.button_class = 'unsuggest-hover-state';
                } else {
                    this.suggest_button_text = this.suggested_text;
                }
            } else {
                this.button_class = "";
            }


        },

        async suggestGem(gem) {

            var suggest = ( gem.status == 1 ) ? true : false;

            const {data} = await this.$http.post(`/api/entities/suggest/gem`, {id: gem.id, suggest: suggest});

            if ( data.success && suggest ) {
                this.$set(gem, "status", 10);
                this.suggest_button_text = this.unsuggest_text;
                this.button_class = 'unsuggest-hover-state';
                this.mixpanel.track("Suggested Gem", gem);

            } else {
                this.$set(gem, "status", 1);
                this.mixpanel.track("Unsuggested Gem", gem);
                this.suggest_button_text = this.suggest_text;
                this.button_class = '';
            }
        },

        produceLink(network_object) {
            return "http://" + network_object.network.url + "/" + network_object.username;
        },

        async getNetworks() {
            var {data} = await this.$http.get(`/api/entities/entity/${this.entity.id}/networks`);
            this.networks = data;

        },

        prevPage() {
            this.$emit('toggleProject');
            history.back();
        }
    }
}
</script>

<style lang="scss">

    .entity-name {
        font-size: 32px !important;
    }

    .bg-grey {
        background: #F6F6F6;
    }


</style>