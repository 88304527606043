<template>
    <div class="gem-container mt-4 shadow-lg rounded-lg bg-white p-4">
        
        <edit-feature-modal
            :visible="editFeaturesModalVisible"
            :gem="gem"
            :features="features"
            @close="editFeaturesModalVisible = false">
        </edit-feature-modal>

        <div>
            <div v-if="gem">
                <div class="">
                    <h3 class="proposal-header"><b>{{gem.name}}</b></h3>
                    <base-icon :size="4" :name="'github'"></base-icon>
                    <base-icon :size="4" class="icon-sm" :name="'link'"></base-icon>
                    <button v-on:click="govModalVisible = true" type="button" class="bg-button-purple font-medium rounded-lg text-sm px-5 py-2 text-center"><span class="text-white font-bold">View</span></button>
                    
                    <div v-bind:key="key" v-for="(theme,key) of themes">
                        <small class="dark tag-bubble ml-4">{{theme}}</small>
                    </div>

                    <div v-bind:key="feature.id" v-for="feature of gem.features">
                        <div v-if="feature.feature">{{feature.feature.name}}</div>
                    </div>

                    <button v-on:click="editFeaturesModalVisible = true" type="button" class="bg-button-purple font-medium rounded-lg text-sm px-5 py-2 text-center"><span class="text-white font-bold">Edit Features</span></button>

                    <!-- gem-scoring :features="gem.features"></gem-scoring -->

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import BaseIcon from '../components/BaseIcon';
import GemScoring from '../components/GemScoring';
import PopperVue from '@soldeplata/popper-vue';
import EditFeatureModal from '../components/EditFeatureModal';


export default {
    props: ['gem','isActive','features'],
    components: {
        BaseIcon,
        PopperVue,
        GemScoring,
        EditFeatureModal
    },
    computed: {

    },
    data() {
        return {
            themes: [],
            editFeaturesModalVisible: false
        }
    },
    watch: {

    },
    mounted () {
        this.dedupeTags();
    },
    methods: {
        async dedupeTags() {
            var themes = {};
            for ( var theme of this.gem.themes ) {
                themes[theme.id] = theme.name;
            }
            console.log(themes);
            this.themes = Object.values(themes);
        }
    }
}
</script>

<style lang="scss">

    .icon-sm {
        font-size: 10px !important;
    }

    .border-color-green {
        border-color: #3EB643 !important;
    }

    .border-color-orange {
        border-color: #FF5E49 !important;

        &.gem-container {
            border-color: #FF5E49 !important;
            border: solid;
            border-width: 1px;
        }
    }

    .body-green {
        background: rgba(62, 182, 67, .1);
    }

    .grey-border {
        border: 1px solid #5F629F;
    }

    .abstain-button {
        color: #5F629F
    }

    .label-size {
        font-size: 13px;
    }

    .proposal-header {
        font-size: 24px;
    }

    .popper-vue__popper {
        background: white;
        padding:  10px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px #999;
    }

</style>