<template>
  <div class="home">
    <header-bar class="">
        <h1 class="text-2xl">Projects</h1>
    </header-bar>

    <div class="">
        <project v-if="Object.keys(activeEntity).length != 0" @removeProject="removeProject" @openEnrichment="openEnrichment" @openAddTags="openAddTags" :entity="activeEntity"></project>
    
    </div>

    </div>
</template>
<script>

    import Project from '../components/Project';
    import HeaderBar from '../components/HeaderBar';
    
    export default {
        name: 'single-project',
        components: {
            Project,
            HeaderBar
        },
        data() {
            return {
                suggested_text: "Suggested as a potential gem",
                unsuggest_text: "Unsuggest as a potential gem",
                suggest_text: "Suggest as a potential gem",
                activeEntity: {},
                enrichmentModalVisible: false,
                addTagsModalVisible: false,
                features: {},
                tags: [],
                entities: [],
                isLoading: false,
                editFeaturesModalVisible: false
            }
        },

        computed: {
            
        },
        watch: {
            
        },

        beforeMount() {

        },

        mounted() {
            this.getProject();
            this.getTags();
            this.getFeatures();
        },

        methods: {

            async removeProject(obj) {

                if ( obj.status == 0 ) {
                    this.undoRemoveProject(obj);
                } else {
                    const { data } = await this.$http.get(`api/entities/remove/` + obj.id);
                    obj.status = 0;
                    this.mixpanel.track("Archived Project", obj);
                }
            },

            async undoRemoveProject(obj) {
                const { data } = await this.$http.get(`api/entities/undo-remove/` + obj.id);
                obj.status = 1;
                this.mixpanel.track("Unarchived Project", obj);
            },


            openEnrichment(entity) {
                this.enrichmentModalVisible = true;
                this.activeEntity = entity;

            },

            openAddTags(entity) {
                this.addTagsModalVisible = true;
                this.activeEntity = entity;

            },

            async getFeatures() {
                const { data } = await this.$http.get(`/themes/features`);
                this.features = data;
            },

            async getTags() {

                const { data } = await this.$http.get(`/api/tags/all`);
                this.tags = data;

            },

            async getProject() {
                
                this.isLoading = true;

                const { data } = await this.$http.get(`/api/entities/entity/${this.$route.params.id}`);

                data['voted'] = false;

                for ( var vote_key in data['votes'] ) {
                        
                    if (data['votes'][vote_key].user_id == this.$store.getters.me.id )
                        data['voted'] = true;
                }

                this.activeEntity = data;
                this.isLoading = false;

            },

            async comment(object, type) {

                var type_id = ( type == "theme" ) ? 19 : 18;

                var post_data = {
                    "object_id": object.id,
                    "user_id": this.$store.getters.me.id,
                    "type_id": type_id,
                    "value": object.existing_comment
                };

                if ( typeof object.event_id != "undefined" )
                    post_data['event_id'] = object.event_id;

                const { data } = await this.$http.post(`/users/comment/new`, post_data);

                object.comments.push(data.data);
                this.$set(object, "comments", object.comments);
                object.commented = true;
                 object.existing_comment = '';

            }
        }

    }
</script>

<style lang="scss" scoped>
    
</style>
