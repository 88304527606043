<template>
  <div class="home">
    <header-bar class="">
        <h1 class="text-2xl">Features</h1>
        <button v-on:click="featureModalVisible = true" type="button" class="bg-button-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-light-purple font-bold">Add Feature</span></button>

    </header-bar>

    <div class="p-5">

        
        <feature-modal
            :visible="featureModalVisible"
            :tags="tags"
            @close="featureModalVisible = false">
        </feature-modal>
        <spinner v-if="isLoading"></spinner>
        <div id="tag-container">
            
            <div class="mt-3 mt-2 standard-border rounded-lg bg-white p-4" v-for="feature in features" :key="feature.id">
                <div>
                    <div>Name: {{feature.name}}</div>
                    
                    <div v-if="feature.type == 'range'">
                        Type: <span class="capitalize">{{feature.type}}</span>

                        <div>Range: {{feature.settings.range.start}} - {{feature.settings.range.end}}</div>
                    </div>

                    <div v-if="feature.type != 'range'"> Type: {{feature.type}}</div>
                    <div>Frequency: <span class="capitalize">{{feature.frequency}}</span></div>

                    <div v-if="feature.frequency == 'series'">
                        Series Interval: <span class="capitalize">{{feature.settings.interval}}</span>
                    </div>

                    <div>Input Type: <span class="capitalize">{{feature.input_type}}</span></div>

                    <div class="" v-if="!feature.is_standard && feature.theme_features.length > 0">
                        Themes:
                        <span v-bind:key="theme_feature.id" v-for="theme_feature of feature.theme_features">
                            
                            <small class="dark tag-bubble mr-1">{{theme_feature.theme.name}}</small>

                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>
<script>

    import FeatureModal from '../components/FeatureModal';
    import HeaderBar from '../components/HeaderBar';
    import Spinner from '../components/Spinner';

    export default {
        name: 'Features',
        components: {
            FeatureModal,
            Spinner,
            HeaderBar
        },
        data() {
            return {
                featureModalVisible: false,
                features: [],
                tags: [],
                isLoading: false
            }
        },

        computed: {
            
        },
        watch: {

        },

        beforeMount() {

        },

        mounted() {
            this.getFeatures();
        },

        methods: {

            async getFeatures() {

                const { data } = await this.$http.get(`/api/themes/features`);
                this.features = data;

            }
        }

    }
</script>

<style>


</style>
