<template>
  <div class="home">
    <header-bar class="">
        <h1 class="text-2xl">Projects</h1>

        <button v-on:click="addProjectModalVisible = true" type="button" class="bg-button-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-light-purple font-bold">Add Project</span></button>
    </header-bar>

    <div class="">
        <enrichment-modal
            :visible="enrichmentModalVisible"
            :entity="activeEntity"
            @close="enrichmentModalVisible = false">
        </enrichment-modal>

        <add-tags-modal
            v-if="activeEntity"
            :visible="addTagsModalVisible"
            :entity="activeEntity"
            :tags="tags"
            @updateEntity="updateEntity"
            @close="addTagsModalVisible = false"
        >
        </add-tags-modal>

        <add-project-modal
            :visible="addProjectModalVisible"
            :tags="tags"
            @addEntity="addEntity"
            @reloadEntites="getProjects"
            @close="addProjectModalVisible = false"
        >
        </add-project-modal>
        <div v-if="!singleView" class="p-5">

            <spinner v-if="isLoading"></spinner>
            
            <div>
                <div class="text-center" v-if="entities.length == 0"><i>No Projects Found</i></div>
                
                <div class="w-full pt-5">
                    <input autocomplete="off" v-model="searchText" v-on:keyup.enter="searchProjects($event.target.value)" class="appearance-none block w-full bg-white standard-border text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:bg-white" type="text" placeholder="search...">
                    <small><a v-on:click="getProjects()" class="cursor-pointer underline">clear</a></small>
                </div>

                <paginate @paginate="getProjects" :current_page="parseInt(entities.page)" :total_results="entities.count" :max_per_page="max_per_page" class="-mt-4"></paginate>
                <select class="standard-border p-2 pl-3 rounded pr-0" v-model="max_per_page">
                    <option v-bind:key="key" v-for="(page_count, key) of page_counts" :value="page_count">{{page_count}} per page</option>
                </select>
                
                <select class="standard-border pr-3 ml-2 p-2 pl-3 rounded pr-0" v-model="simple_filter">
                    <option v-bind:key="key" v-for="(simple_filter_option, key) of simple_filter_options" :value="simple_filter_option">{{simple_filter_option}}</option>
                </select>
                <div class="" v-bind:key="entity.id" v-for="(entity,to_key) of entities.rows">
                    <project-tile @removeProject="removeProject" class="standard-border" @projectView="projectView" @resortProjects="resortProjects" @openAddTags="openAddTags" @openEnrichment="openEnrichment" :entity="entity" :to_key="to_key"></project-tile>
                </div>

                <div v-if="entities.count && showPagination">
                    <!-- span v-bind:key="page" v-for="page in parseInt(entities.count/100)">
                        <a class="underline mr-2" href="#" v-on:click="pageNum = page">{{page}}</a>
                    </span-->

                    <paginate @paginate="getProjects" :current_page="parseInt(entities.page)" :total_results="entities.count" :max_per_page="max_per_page" class="mt-5"></paginate>

                </div>

            </div>
        </div>

        <div v-if="singleView">
            
            <project @removeProject="removeProject" @openEnrichment="openEnrichment" @openAddTags="openAddTags" @toggleProject="toggleProject" :entity="activeEntity"></project>

        </div>
    </div>

    </div>
</template>
<script>

    import EnrichmentModal from '../components/EnrichmentModal';
    import ProjectTile from '../components/ProjectTile';
    import HeaderBar from '../components/HeaderBar';
    import Spinner from '../components/Spinner';
    import BaseIcon from '../components/BaseIcon';
    import EditFeatureModal from '../components/EditFeatureModal';
    import AddTagsModal from '../components/AddTagsModal';
    import AddProjectModal from '../components/AddProjectModal';
    import Project from '../components/Project';
    import Paginate from '../components/Paginate';

    export default {
        name: 'recently-added',
        components: {
            Paginate,
            Spinner,
            HeaderBar,
            BaseIcon,
            EnrichmentModal,
            EditFeatureModal,
            ProjectTile,
            AddTagsModal,
            AddProjectModal,
            Project
        },
        data() {
            return {
                suggested_text: "Suggested as a potential gem",
                unsuggest_text: "Unsuggest as a potential gem",
                suggest_text: "Suggest as a potential gem",
                activeEntity: {},
                addProjectModalVisible: false,
                enrichmentModalVisible: false,
                addTagsModalVisible: false,
                features: {},
                tags: [],
                entities: [],
                isLoading: false,
                editFeaturesModalVisible: false,
                searchText: "",
                showPagination: true,
                singleView: false,
                current_page: 1,
                max_per_page: 25,
                page_counts: [10,25,50,100,200],
                simple_filter: "all",
                simple_filter_options: ["all","voted","non-voted", "archived"]
            }
        },

        computed: {
            
        },
        watch: {
            max_per_page: function() {
                this.getProjects();
            },

            simple_filter: function() {
                this.getProjects();   
            }
        },

        beforeMount() {

        },

        mounted() {
            this.getProjects();
            this.getTags();
            this.getFeatures();
        },

        methods: {

            async removeProject(obj) {

                if ( obj.status == 0 ) {
                    this.undoRemoveProject(obj);
                } else {
                    const { data } = await this.$http.get(`api/entities/remove/` + obj.id);
                    obj.status = 0;
                    this.removeEntity(obj.id);
                    this.mixpanel.track("Archived Project", obj);
                }
            },

            async undoRemoveProject(obj) {
                const { data } = await this.$http.get(`api/entities/undo-remove/` + obj.id);
                obj.status = 1;
                this.mixpanel.track("Unarchived Project", obj);
            },

            toggleProject() {
                console.log('getting here?');
                this.singleView = !this.singleView;
            },

            projectView(entity) {
                this.singleView = true;
                this.activeEntity = entity;
            },

            addEntity() {
                console.log('getting here');
            },

            async updateEntity(tags, entity) {
                const {data} = await this.$http.get(`/api/entities/entity/${entity.id}`);

                for ( var key in this.entities.rows ) {
                    if ( entity.id == this.entities.rows[key].id ) {
                        this.$set(this.entities.rows,key,data);
                    }
                }

            },

            resortProjects() {
                var entities = this.entities.rows;
                entities.sort(function(a,b){
                    return b.votes.length - a.votes.length;
                });
            },

            removeEntity(id) {

                var the_entities = ( this.entities.rows ) ? this.entities.rows : this.entities;

            },

            openEnrichment(entity) {
                this.enrichmentModalVisible = true;
                this.activeEntity = entity;

            },

            openAddTags(entity) {
                this.addTagsModalVisible = true;
                this.activeEntity = entity;

            },

            async getFeatures() {
                const { data } = await this.$http.get(`/themes/features`);
                this.features = data;
            },

            async getTags() {

                const { data } = await this.$http.get(`/api/tags/all`);
                this.tags = data;

            },

            async searchProjects() {
                this.showPagination = false;
                this.isLoading = true;

                const { data } = await this.$http.get(`/api/entities/search?query=`+this.searchText);

                for ( var item of data.rows ) {

                    if ( item != null ) {
                        item['voted'] = false;

                        for ( var vote_key in item['votes'] ) {
                                
                            if (item['votes'][vote_key].user_id == this.$store.getters.me.id )
                                item['voted'] = true;
                        }
                    }

                }

                this.entities = data;
                this.isLoading = false;
                this.resortProjects();

            },

            async getProjects(page = this.current_page) {
                this.showPagination = true;
                this.searchText = "";
                this.isLoading = true;

                const { data } = await this.$http.get(`/api/entities/new?page=${page}&max_per_page=${this.max_per_page}&simple_filter=${this.simple_filter}`);
                
                for ( var item of data.rows ) {

                    if ( item != null ) {
                        item['voted'] = false;

                        for ( var vote_key in item['votes'] ) {
                                
                            if (item['votes'][vote_key].user_id == this.$store.getters.me.id )
                                item['voted'] = true;
                        }
                    }

                }

                this.entities = data;
                this.isLoading = false;
                this.resortProjects();
            },

            async comment(object, type) {

                var type_id = ( type == "theme" ) ? 19 : 18;

                var post_data = {
                    "object_id": object.id,
                    "user_id": this.$store.getters.me.id,
                    "type_id": type_id,
                    "value": object.existing_comment
                };

                if ( typeof object.event_id != "undefined" )
                    post_data['event_id'] = object.event_id;

                const { data } = await this.$http.post(`/users/comment/new`, post_data);

                object.comments.push(data.data);
                this.$set(object, "comments", object.comments);
                object.commented = true;
                 object.existing_comment = '';

            }
        }

    }
</script>

<style lang="scss" scoped>
    
</style>
