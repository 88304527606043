<template>
    <div :class="(isActive) ? 'border-solid border-color-orange' : ''" class="proposal-container mt-4 rounded-lg bg-white p-4">
        <div>
            <div v-if="proposal">
                <div class="flex justify-between">
                    <h3 class="proposal-header"><b>Proposal #{{proposal.id}}</b></h3>
                    <div v-if="!is_expired" class=""><!-- base-icon class="text-orange relative top-1 right-2" :name='"calendar"'></base-icon --><span class="text-dark-purple">Expires in</span> <span class="text-button-green">{{ proposal.expiration | moment("from", new Date(), true) }}</span></div>
                <div v-else>This proposal has expired.</div>
                </div>
                
                <p class="text-purple">By {{proposal.user.first_name}}</p>
                
            </div>
            <div class="mt-2">
                <div class="mb-3"><b>{{proposal.name}}</b></div>
                <div class="mb-2 label-size"><label class='uppercase text-dark-purple'><b>Proposal Body</b></label></div>
                <div class="body-green p-5 pb-3 pt-1 rounded-lg">
                    <p class="mt-2 font-thin">{{proposal.description}}</p>
                </div>
            </div>

            <span v-if="proposal.settings.unanimous">Requires Unanimous Votes</span>

            
            <div class="mt-4 flex justify-between">
                <div v-if="!is_expired">
                    <button v-on:click="vote(1)" type="button" class="border-solid border-2 mr-2 border-color-green bg-button-green font-medium rounded-lg text-sm p-5 py-3 text-center"><span class="font-bold text-white">Yay <span class="opacity-50 ml-5">{{vote_stats.yay}}</span></span></button>
                    <button v-on:click="vote(2)" type="button" class="border-solid border-2 mr-2 border-color-orange bg-button-orange font-medium rounded-lg text-sm p-5 py-3 text-center"><span class="font-bold text-white">Nay <span class="opacity-50 ml-5">{{vote_stats.nay}}</span></span></button>
                    <button v-on:click="vote(3)" type="button" class="grey-border border-solid border-2 mr-2 bg-white font-medium rounded-lg text-sm p-5 py-3 text-center"><span class="font-bold abstain-button">Abstain <span class="opacity-50 ml-5">{{vote_stats.abstain}}</span></span></button>
                </div>

                <div>

                    <PopperVue :show="show" :options="options">
                        <span v-on:click="show = !show" class="text-purple underline">{{proposal.votes.length}} <span v-if="proposal.votes.length > 1 || proposal.votes.length == 0">have</span> <span v-else>has</span> voted</span>
                        <template #popper>
                            <div v-bind:key="vote.id" v-for="vote of proposal.votes">
                                {{vote.users[0].first_name}} {{vote.users[0].last_name}}: {{vote_ids[vote.status]}}
                            </div>
                        </template>
                    </PopperVue>

                    <span v-on:click="show_comments = !show_comments" class="cursor-pointer text-purple underline">comments</span>
                </div>

            </div>
            <div v-if="show_comments" class="comments-container mt-5">

                    <comment class="" :object="proposal" :type_id="20"></comment>

                </div>

        </div>
    </div>

</template>

<script>

import BaseIcon from '../components/BaseIcon';
import PopperVue from '@soldeplata/popper-vue';
import Comment from '../components/Comment';


export default {
    props: ['proposal','isActive','tip_direction'],
    components: {
        BaseIcon,
        PopperVue,
        Comment
    },
    computed: {
        is_expired: function() {
            return ( this.moment(this.proposal.expiration).isBefore(this.moment()) ) ? true : false;
        }
    },
    data() {
        return {
            "user_name": this.$store.getters.me.first_name,
            "vote_stats": {
                "yay": 0,
                "nay": 0,
                "abstain": 0
            },
            "options": {
                "placement": this.tip_direction
            },
            "show": false,
            "vote_ids": {
                1: "yay",
                2: "nay",
                3: "abstain"
            },
            "show_comments": false
        }
    },
    watch: {
        proposal: function() {
            
            this.vote_stats = {
                "yay": 0,
                "nay": 0,
                "abstain": 0
            };

            this.aggregate_votes();
        }
    },
    mounted () {
        this.aggregate_votes();


    },
    methods: {

        async vote(status) {

            var post_data = {
                "object_id": this.proposal.id,
                "user_id": this.$store.getters.me.id,
                "type_id": 20,
                "status": status
            };

            await this.$http.post(`/users/vote/new`, post_data);

            this.$emit('voted');

        },

        async aggregate_votes() {

            for ( var v of this.proposal.votes ) {
                
                if( v.status == 1 ) {
                    
                    this.vote_stats.yay++;

                } else if( v.status == 2 ) {
                    
                    this.vote_stats.nay++;
                
                } else if( v.status == 3) {
                
                    this.vote_stats.abstain++;
                
                }
            }
        }

    }
}
</script>

<style lang="scss">

    .border-color-green {
        border-color: #3EB643 !important;
    }

    .border-color-orange {
        border-color: #FF5E49 !important;

        &.proposal-container {
            border-color: #FF5E49 !important;
            border: solid;
            border-width: 1px;
        }
    }

    .body-green {
        background: rgba(62, 182, 67, .1);
    }

    .grey-border {
        border: 1px solid #5F629F;
    }

    .abstain-button {
        color: #5F629F
    }

    .label-size {
        font-size: 13px;
    }

    .proposal-header {
        font-size: 24px;
    }

    .popper-vue__popper {
        background: white;
        padding:  10px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px #999;
    }

</style>