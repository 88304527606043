<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')">

        <div class="p-6 space-y-6">
                    
            <form class="w-full max-w-lg">
                
                <!-- NAME -->

                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Proposal Name
                </label>
                <input autocomplete="off" v-model="name" class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" type="text" placeholder="My Proposal Name">

                <!-- DESCRIPTION -->

                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Proposal Body
                </label>
                <textarea v-model="description" class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" placeholder="This is my proposal..."></textarea>

                <!-- EXPIRATION -->
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Expiration Date
                </label>
                <datepicker v-model="expiration" class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-white"></datepicker>

                <!-- OTHER SETTINGS -->

            </form>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="saveProposal" data-modal-toggle="defaultModal" type="button" class="text-white bg-button-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-white">Save</span></button>
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
        </div>

    </base-modal>
</template>

<script>

import Datepicker from 'vuejs-datepicker';

export default {
    props: ['visible'],
    components: {
        Datepicker
    },
    computed: {
        title () {
            return "Add Proposal";
        }
    },
    data() {
        return {
            "name": "",
            "description": "",
            "expiration": new Date(),
            "settings": {}
        }
    },
    watch: {
    },
    mounted () {

    },
    methods: {
        async submitForm() {
            this.submitting = true;
            
        },

        async saveProposal() {

            var post_data = {
                "name": this.name,
                "description": this.description,
                "expiration": this.expiration,
                "settings": this.settings
            };

            const { data } = await this.$http.post(`/api/governance/proposal/new`, post_data);
        
            if (data.success) {
                this.$emit('close');
                this.tagMatches = [];
                this.tagText = ""; 
                data.proposal.votes = [];
                data.proposal.user = this.$store.getters.me;
                this.$emit('savedPropsal', data.proposal);
                this.mixpanel.track("Saved Proposal", post_data);
            }
        },

    }
}
</script>