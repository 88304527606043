<template>
    <main>
        <div class="min-w-screen flex items-center">
            <div class="w-full max-w-3xl">
                <div class="-mx-2 md:flex">
                    <div class="relative flex-auto">
                        <line-chart v-if="chartData.all_loomi" :options="chartOptions" :chartData="chartData.all_loomi"></line-chart>
                    </div>
                    <div class="relative flex-auto">
                        <line-chart v-if="chartData.loomi_usd" :options="chartOptions" :chartData="chartData.loomi_usd"></line-chart>
                    </div>

                </div>
            </div>
        </div>
        
        <div v-if="Object.keys(data).length > 0" class="my-2 flex flex-row pb-0 pl-2 py-3" tabindex="0">
            <div class="p-10">
                <h2>LOOMI</h2>
                <img class="w-10" src="https://lh3.googleusercontent.com/r_FGZQ0mgacLAOzH1MxC9n-yyygNmg37QyjYvM3g4HCl1mOkeGif7_8IW62v_R4Tvx9O9EWdHkNmiQQW30DNxwcvuSbg5EM1FQeSDIo=s130">
                <div class="">
                    <p>erc20 LOOMI: {{numberWithCommas(parseFloat(data.loomi.token_supply).toFixed(2))}}</p>
                    <p>In-game LOOMI: {{numberWithCommas(data.loomi.game_loomi)}}</p>
                    <p>Spent LOOMI: {{numberWithCommas(data.loomi.spent_loomi)}}</p>
                    <p>Staked Creepz: {{numberWithCommas(data.loomi.staked_creepz)}}</p>
                    <p>Staked Armouries: {{numberWithCommas(data.loomi.staked_armouries)}}</p>
                    <p>Bribe Pot: {{numberWithCommas(data.loomi.bribe_pot)}}</p>
                    <p>LOOMI Yield: {{numberWithCommas(data.loomi.yield_loomi)}}</p>
                    <p>Total Players: {{numberWithCommas(data.loomi.players)}}</p>
                    <p>Token Supply: {{numberWithCommas(parseInt(data.loomi.token_supply))}}</p>
                    <p>ETH Price: {{parseFloat(data.loomi.eth).toFixed(10)}}</p>
                    <p>USD Price: {{parseFloat(data.loomi.usd).toFixed(5)}}</p>
                </div>
            </div>

            <div class="p-10">
                <h2>Genesis Creepz</h2>
                <img class="w-10" src="https://lh3.googleusercontent.com/IEibLdVPfEtk91ugpvV-EMbrQK3jL7sFjW2YAfOE635wHNJuroFiwizoJdHthRp6wAoUQP21S6yZnCbAUlcPyTomDH28Tyz4qjGlXw=w600">

                <div class="">
                    <p>ETH Price: {{data.genesis.opensea_floor.eth}}</p>
                    <p>USD Price: {{numberWithCommas(parseFloat(data.genesis.opensea_floor.usd).toFixed(2))}}</p>
                    <p>LOOMI Price: {{numberWithCommas(parseFloat(data.genesis.opensea_floor.loomi).toFixed(2))}}</p>
                    <p>Yield: {{parseFloat(data.genesis.opensea_floor.yield).toFixed(2)}}%</p>
                </div>
            </div>

            <div class="p-10">
                <h2>Armoury</h2>
                <img class="w-10" src="https://api.creepz.co/armoury/11601/image/?1643513033303">
                <div class="">
                    <p>ETH Price: {{data.armoury.opensea_floor.eth}}</p>
                    <p>USD Price: {{numberWithCommas(parseFloat(data.armoury.opensea_floor.usd).toFixed(2))}}</p>
                    <p>LOOMI Price: {{numberWithCommas(parseFloat(data.armoury.opensea_floor.loomi).toFixed(2))}}</p>
                    <p>Yield: {{parseFloat(data.armoury.opensea_floor.yield).toFixed(2)}}%</p>
                </div>
            </div>

            <div class="p-10">
                <h2>Vault</h2>
                <img class="w-10" src="https://lh3.googleusercontent.com/8nY5FxzqXpZGH-VZ8yDK3vUk94mf7bXLlObGM14HaqGnwXFVpAKr0iZ5FG71FXPTDu46uR1HJyHsTz3sjf4F16E1uqPTBNWtnkJu3A=s130">
                <div class="">
                    <p>ETH Price: {{data.vault.opensea_floor.eth}}</p>
                    <p>USD Price: {{numberWithCommas(parseFloat(data.vault.opensea_floor.usd).toFixed(2))}}</p>
                    <p>LOOMI Price: {{numberWithCommas(parseFloat(data.vault.opensea_floor.loomi).toFixed(2))}}</p>
                </div>
            </div>

            <div class="p-10">
                <h2>Mega Shape Shifterz</h2>
                <img class="w-10" src="https://lh3.googleusercontent.com/q2Mbczrn-KvVuawxYccKIPchWTP953CyB5ZNYG8BTW1B5PSz4t0XQfpU4SYmZP2CplueFA7IOB3nk5KxuMTEAiTK3PKfPm6WCfTV=s130">
                <div class="">
                    <p>ETH Price: {{data.mega_shape_shifterz.opensea_floor.eth}}</p>
                    <p>USD Price: {{numberWithCommas(parseFloat(data.mega_shape_shifterz.opensea_floor.usd).toFixed(2))}}</p>
                    <p>LOOMI Price: {{numberWithCommas(parseFloat(data.mega_shape_shifterz.opensea_floor.loomi).toFixed(2))}}</p>
                </div>
            </div>

            <div class="p-10">
                <h2>Shape Shifterz</h2>
                <img class="w-10" src="https://lh3.googleusercontent.com/63HmJBlg3RvZ-7RVdZpO5q_SYKqWi4-HaBItKtfKpgAjUJd4Jdi6Fb2CrEMBRbcwJeI3oMLj9utFA_eix6CQ4DiQaBugGTvSck61gw=s130">
                <div class="">
                    <p>ETH Price: {{data.shape_shifterz.opensea_floor.eth}}</p>
                    <p>USD Price: {{numberWithCommas(parseFloat(data.shape_shifterz.opensea_floor.usd).toFixed(2))}}</p>
                    <p>LOOMI Price: {{numberWithCommas(parseFloat(data.shape_shifterz.opensea_floor.loomi).toFixed(2))}}</p>
                </div>
            </div>
        </div>

    </main>
</template>
<script>

import LineChart from '../components/LineChart';

export default {
    components: {
        LineChart
    },
    data() {
        return {
            loading: false,
            data: {},
            chartData: {},
            chartOptions: {}
        }
    },
    mounted(){
        this.getData();
        this.getChartData();
    },
    methods: {
        async getData(showLoader = true) {
            this.loading = showLoader ? true : false;

            // get all entities
            const { data } = await this.$http.get('/api/stats/creepz');
            this.data = data;
            this.loading = false;
        },
        async getChartData() {
            const { data } = await this.$http.get('/api/stats/creepz-charts');
            this.chartOptions = {
                responsive:true
            }
            this.chartData = {
                "all_loomi": {   
                    labels: data.labels,
                    datasets: [{
                        axis: 'y',
                        label: 'Genesis/LOOMI',
                        data: data.data.genesis_loomi_price,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(255, 205, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                      },
                      {
                        axis: 'y',
                        label: 'Armoury/LOOMI',
                        data: data.data.armoury_loomi_price,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(255, 205, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                      },
                      {
                        axis: 'y',
                        label: 'Shape Shifterz/LOOMI',
                        data: data.data.shape_shifterz_loomi_price,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(255, 205, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                      },
                      {
                        axis: 'y',
                        label: 'Mega ShapeShifterz/LOOMI',
                        data: data.data.mega_shape_shifterz_loomi_price,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(255, 205, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                      },
                      {
                        axis: 'y',
                        label: 'Vault/LOOMI',
                        data: data.data.vault_loomi_price,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(255, 205, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(201, 203, 207, 0.2)'
                        ],
                        borderColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)'
                        ],
                        borderWidth: 1
                      }]
                },
                "loomi_usd": {
                    labels: data.labels,
                    datasets: [
                        {
                            axis: 'y',
                            label: 'LOOMI/USD',
                            data: data.data.loomi_usd_price,
                            fill: false,
                            backgroundColor: [
                              'rgba(255, 99, 132, 0.2)',
                              'rgba(255, 159, 64, 0.2)',
                              'rgba(255, 205, 86, 0.2)',
                              'rgba(75, 192, 192, 0.2)',
                              'rgba(54, 162, 235, 0.2)',
                              'rgba(153, 102, 255, 0.2)',
                              'rgba(201, 203, 207, 0.2)'
                            ],
                            borderColor: [
                              'rgb(255, 99, 132)',
                              'rgb(255, 159, 64)',
                              'rgb(255, 205, 86)',
                              'rgb(75, 192, 192)',
                              'rgb(54, 162, 235)',
                              'rgb(153, 102, 255)',
                              'rgb(201, 203, 207)'
                            ],
                            borderWidth: 1
                          }
                    ]
                }
            };
            this.loading = false;  
        }
        

    }
}
</script>