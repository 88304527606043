<template>

    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >

        <div class="p-6 space-y-6">
                    
            <form class="w-full max-w-lg">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Theme Name
                  </label>
                  <input autocomplete="off" v-model="themeFormName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-first-name" type="text" placeholder="My Theme Name">
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                    Tags
                  </label>

                  <div class="tag-container">
                    <div v-for="activeTag of activeTags" v-bind:key="activeTag.id" class="inline-block p-2 m-1 ml-0 bg-gray-50 rounded-md">
                        <small>{{activeTag.name}}<span class="ml-3 cursor-pointer" v-on:click="removeTag(activeTag)">x</span></small>
                    </div>
                  </div>

                  <input autocomplete="off" v-model="tagText" v-on:keyup.enter="add_new_tag($event.target.value)" v-on:keyup="autoCompleteTags($event.target.value)" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-first-name" type="text" placeholder="Tag">

                  <div v-if="tagMatches.length > 0" class="fixed bg-gray-50 p-1 sm:w-2/4 md:w-1/4">
                      <div v-for="match of tagMatches" v-bind:key="match.id">
                          <div v-on:click="addTag(match)" :id="match.name" class="p-2 cursor-pointer hover:bg-gray-100">{{match.name}}</div>
                      </div>

                  </div>
                </div>
                
              </div>
            </form>

        </div>

        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button v-on:click="saveTheme" data-modal-toggle="defaultModal" type="button" class="bg-green-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
            <button v-on:click="$emit('close')" data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
        </div>

    </base-modal>
</template>

<script>

export default {
    props: ['visible', 'tags'],
    components: {
    },
    computed: {
        title () {
            return "Add Theme";
        }
    },
    data() {
        return {
            themeFormName: "",
            themeFormId: null,
            tagText: "",
            tagMatches: [],
            activeTags: []
        }
    },
    watch: {
    },
    mounted () {

    },
    methods: {
        async submitForm() {
            this.submitting = true;
            
        },
        clearForm() {
            
            this.themeFormName = "";
            this.activeTags = [];
        },

        add_new_tag(value) {

            //const regexp = new RegExp(value, 'i');
            var matches = this.activeTags.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));

            if ( matches.length == 0 ) {
                this.activeTags.push({'name': value, 'id': null});
                this.tagMatches = [];
                this.tagText = "";                
            }

        },

        autoCompleteTags(value) {
            
            if ( value.length > 2 ) {
                //const regexp = new RegExp(value, 'i');
                this.tagMatches = this.tags.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.tagMatches = [];
            }

        },

        addTag(tag) {

            this.activeTags.push(tag);
            this.tagMatches = [];
            this.tagText = "";

        },

        async saveTheme() {

            var post_data = {
                "id": this.themeFormId,
                "name": this.themeFormName,
                "tags": this.activeTags
            };

            const { data } = await this.$http.post(`/themes/save`, post_data);
            
            if (data.success) {
                this.$emit('close');
                this.tagMatches = [];
                this.tagText = ""; 
                this.clearForm();
            }
            this.mixpanel.track("Added Theme", post_data);

        },

        removeTag(tag) {
            console.log(tag);
            var new_arr = [];
            for ( var active_tag of this.activeTags ) {
                if ( active_tag.name != tag.name ) {
                    new_arr.push(active_tag);
                }
            }

            console.log(new_arr);
            this.activeTags = new_arr;

        },
    }
}
</script>