<template>
    <div class="home">
        <header-bar class="">
            <h1 class="text-2xl">Gems</h1>
            <!-- button type="button" class="bg-button-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-light-purple font-bold">Add Potential Gem</span></button -->
        </header-bar>
        <div class="py-2 p-6">

            <div v-if="!singleView">
                <div class="" v-bind:key="entity.id" v-for="(entity,to_key) of potential_gems">
                    <project-tile class="standard-border" :id="'entity-'+entity.id" @resortProjects="resortProjects" @projectView="projectView" @openEnrichment="openEnrichment" @openAddTags="openAddTags(entity)" @removeEntity="removeEntity" :entity="entity" :to_key="to_key"></project-tile>
                </div>
            </div>

            <div v-if="singleView">
                <project @removeEntity="removeEntity" @openEnrichment="openEnrichment" @openAddTags="openAddTags(entity)" @toggleProject="toggleProject" :entity="activeEntity"></project>
            </div>
        </div>
        <add-tags-modal
            v-if="activeEntity"
            :visible="addTagsModalVisible"
            :entity="activeEntity"
            :tags="tags"
            @updateEntity="updateEntity"
            @close="addTagsModalVisible = false"
        >
        </add-tags-modal>
        <enrichment-modal
            :visible="enrichmentModalVisible"
            :entity="activeEntity"
            @close="enrichmentModalVisible = false">
        </enrichment-modal>
    </div>
</template>
<script>
import EnrichmentModal from '../components/EnrichmentModal';
import HeaderBar from '../components/HeaderBar';
import AddTagsModal from '../components/AddTagsModal';
import BaseIcon from '../components/BaseIcon';
import GemComponent from '../components/GemComponent';
import ProjectTile from '../components/ProjectTile';
import Project from '../components/Project';

export default {
    components: {
        HeaderBar,
        BaseIcon,
        GemComponent,
        ProjectTile,
        Project,
        EnrichmentModal,
        AddTagsModal
    },
    data() {
        return {
            potential_gems: [],
            enrichmentModalVisible: false,
            addTagsModalVisible: false,
            standard_features: [],
            tags: [],
            features: [],
            activeEntity: {},
            singleView: false
        }
    },
    mounted(){
        this.getStandardFeatures();
        this.getData(true);
        this.getFeatures();
        this.getTags();
    },
    methods: {

        async getTags() {

            const { data } = await this.$http.get(`/api/tags/all`);
            this.tags = data;

        },


        toggleProject() {
            console.log('getting here?');
            this.singleView = !this.singleView;
        },

        projectView(entity) {
            this.singleView = true;
            this.activeEntity = entity;
        },

        removeEntity() {
            console.log('removeEntity');
        },

        resortProjects() {
            console.log('resortProjects');
        },
        openEnrichment(entity) {
            this.enrichmentModalVisible = true;
            this.activeEntity = entity;

        },

        openAddTags(entity) {
            this.addTagsModalVisible = true;
            this.activeEntity = entity;

        },

        async getFeatures() {
            const { data } = await this.$http.get(`/themes/features`);
            this.features = data;
        },

        async getStandardFeatures() {

            const { data } = await this.$http.get('/api/entities/standard-features');
            this.standard_features = data;

        },

        async updateEntity(tags, entity) {
            const {data} = await this.$http.get(`/api/entities/entity/${entity.id}`);

            for ( var key in this.entities.rows ) {
                if ( entity.id == this.entities.rows[key].id ) {
                    this.$set(this.entities.rows,key,data);
                }
            }

        },

        async getData() {
            this.isLoading = true;

            const { data } = await this.$http.get('/api/entities/potential-gems');
            this.getStandardFeatures();
            for ( var gem of data ) {
                var consolidated_features = [];
                var associated_themes = [];

                gem['voted'] = false;

                for ( var vote_key in gem['votes'] ) {
                        
                    if (gem['votes'][vote_key].user_id == this.$store.getters.me.id )
                        gem['voted'] = true;
                }

                for ( var tag_object of gem.tag_objects) {

                    if ( tag_object.theme_tags.length > 0 ) {

                        for ( var theme_tag of tag_object.theme_tags ) {

                            associated_themes.push(theme_tag.theme);

                            for ( var feature of theme_tag.theme.theme_features ) {
                                consolidated_features[feature.feature_id] = feature;
                            }
                        }
                    }
                }

                for ( var std_feature of this.standard_features ) {
                    consolidated_features[std_feature.id] = {"feature": std_feature};
                }

                this.$set(gem, "themes", associated_themes); 
                this.$set(gem, "features", Object.values(consolidated_features)); 

            }

            this.potential_gems = data;
            this.isLoading = false;

        }
    }
}
</script>

<style>

</style>