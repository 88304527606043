<template>
    <div class="home">
        <header-bar class="">
            <div class=""><h3>Welcome back, <div class="" style="color: #47E9FF">{{$store.getters.me.public_address}} <span v-if="$store.getters.me.first_name && $store.getters.me.first_name != ''">(AKA {{$store.getters.me.first_name}})</span></div></h3></div>
            <div class="">
                <div class="">Collective: <span style="color: #47E9FF">{{collectiveBalance.toFixed(3)}} ETH</span></div>
                <div class=""># Logged in Last 24 Hours: <span style="color: #47E9FF">{{this.activityCount.one}}</span></div>
                <div class=""># Logged in Last 48 Hours: <span style="color: #47E9FF">{{this.activityCount.two}}</span></div>
                <div class=""># Logged in Last 7 Days: <span style="color: #47E9FF">{{this.activityCount.seven}}</span></div>
                <div class="">ROI to date: <span style="color: #47E9FF">TBD - Need Formula</span></div>
            </div>
        </header-bar>
        <!-- spinner v-if="isLoading"></spinner -->
        <div class="p-10">
            <div class="flex justify-between">
                <div class="">
                    <button v-on:click="govModalVisible = true" type="button" class="bg-button-purple font-medium rounded-lg text-sm px-5 py-2.5 text-center"><span class="text-white font-bold">Add Proposal</span></button>
                </div>
                <div v-if="outstanding_items != 0" class="gov-alert-box text-xs rounded-full bg-medium-orange p-2"><div class="justify-center align-center flex"><base-icon :name='"exclamation"'></base-icon><span class="alert-text">{{outstanding_items}} proposal<span v-if="outstanding_items > 1 || outstanding_items == 0">s</span> urgently need<span v-if="outstanding_items == 1">s</span> your attention</span></div></div>
            </div>
            <h1 class="mt-10 h1-header">Proposals</h1>
            <proposal-modal
                :visible="govModalVisible"
                @close="govModalVisible = false"
                @savedPropsal="savedPropsal"
                >
            </proposal-modal>
            <div class="flex mb-4">
                <div class="w-1/2 mr-4">
                    <div v-bind:key="proposal.id" v-for="proposal of proposals">
                        <proposal v-if="moment(proposal.expiration).isBefore(moment())" class="standard-border" @voted="getData" :proposal="proposal" :tip_direction="'bottom'"></proposal>
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-bind:key="proposal.id" v-for="proposal of proposals">
                        <proposal v-if="!moment(proposal.expiration).isBefore(moment())" class="standard-border" :isActive="true" @voted="getData" :proposal="proposal" :tip_direction="'left'"></proposal>
                    </div>
                </div>
            </div>
            <!-- h1 class="mt-10 h1-header">Investments</h1 -->
        </div>

    </div>
</template>
<script>

import ProposalModal from '../components/ProposalModal';
import Proposal from '../components/Proposal';
import Spinner from '../components/Spinner';
import HeaderBar from '../components/HeaderBar';
import BaseIcon from '../components/BaseIcon';


export default {
    components: {
        ProposalModal,
        Proposal,
        Spinner,
        HeaderBar,
        BaseIcon
    },
    data() {
        return {
            loading: false,
            govModalVisible: false,
            proposals: [],
            isLoading: false,
            outstanding_items: 0,
            collectiveBalance: 0,
            activityCount: 0
        }
    },
    mounted(){
        this.getData(true);
        this.getBalance();
        this.getUserActivity();
    },
    methods: {

        savedPropsal(new_proposal) {

            this.proposals.push(new_proposal);

        },

        async getUserActivity() {

            const { data } = await this.$http.get('/api/users/activity')
            this.activityCount = data;

        },

        async getData() {
            this.isLoading = true;

            // get all proposals
            const { data } = await this.$http.get('/api/governance/proposals');
            var outstanding_items = 0;
            for ( var proposal of data) {
                var user_voted = 0;
                
                for ( var vote of proposal.votes ) {
                    if ( vote.user_id == this.$store.getters.me.id ) {
                        user_voted = 1;
                    }
                }

                if (  user_voted == 0 && this.moment(proposal.expiration).isAfter(this.moment()) ) {
                    console.log("got here hmmm");
                    outstanding_items++;
                }

                proposal.user_voted = user_voted;

            }
            this.outstanding_items = outstanding_items;
            this.proposals = data;
            this.isLoading = false;
        },
        async getBalance() {

            const { data } = await this.$http.get('/api/governance/get-collective-balance');
            this.collectiveBalance = data.balance;

        }
    }
}
</script>

<style>

    .h1-header {
        font-size: 32px;
        font-weight: 600;
    }

    .alert-text {
        position: relative;
        top: 2px;
        margin-left: 5px;
    }

    .gov-alert-box {
        color: white;
    }
</style>