import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/Login.vue';
import Entities from '../views/Entities.vue';

import Events from '../views/Events.vue';
import Funds from '../views/Funds.vue';
import People from '../views/People.vue';
import Projects from '../views/Projects.vue';
import Themes from '../views/Themes.vue';
import Creepz from '../views/Creepz.vue';
import Governance from '../views/Governance.vue';
import Features from '../views/Features.vue';
import Gems from '../views/Gems.vue';
import RecentlyAdded from '../views/RecentlyAdded.vue';
import News from '../views/News.vue';
import SingleProject from '../views/SingleProject.vue';

//import ForgotPassword from '../views/ForgotPassword.vue';
//import PasswordReset from '../views/PasswordReset.vue';
//import NavigationError from '../views/NavigationError.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/themes',
        name: 'themes',
        component: Themes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/entities',
        name: 'entities',
        component: Entities,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/entity/:id',
        name: 'entity',
        component: SingleProject,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/events',
        name: 'events',
        component: Events,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/people',
        name: 'people',
        component: People,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/funds',
        name: 'funds',
        component: Funds,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/creepz',
        name: 'creepz',
        component: Creepz,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Governance,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/features',
        name: 'features',
        component: Features,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gems',
        name: 'gems',
        component: Gems,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: RecentlyAdded,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    window.document.title = to.name ?  'Gem Finders - ' + to.name.charAt(0).toUpperCase() + to.name.slice(1) : 'Home';
    return next();
   
    
})

export default router;
