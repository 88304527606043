<template>
    <div>
        <div v-bind:key="feature.id" v-for="feature of features">

            <div v-if="feature">{{feature}}</div>

            <!-- div>
                <div v-if="feature.feature.method == 'range'" class="flex flex-wrap -mx-3">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <input autocomplete="off" class="appearance-none w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-start" :min="feature.feature.settings.range.start" type="number" placeholder="Start">
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <input autocomplete="off" class="appearance-none w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" id="grid-end" type="number" :max="feature.feature.settings.range.end" placeholder="End">
                    </div>
                </div>
                <input v-if="feature.feature.method == 'number'" class="appearance-none w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:bg-white" type="number" name="feature.feature.name">
            </div-->


        </div>
    </div>

</template>

<script>

import BaseIcon from '../components/BaseIcon';
import PopperVue from '@soldeplata/popper-vue';


export default {
    props: ['features'],
    components: {
        BaseIcon,
        PopperVue
    },
    computed: {
        
    },
    data() {
        return {
            
        }
    },
    watch: {

    },
    mounted () {
        
    },
    methods: {

    }
}
</script>

<style lang="scss">

    

</style>