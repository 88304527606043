import '@/assets/tailwind.css'
import Vue from 'vue';
import router from './router';
import App from './App.vue';
import store from "./store";
import axios from "axios";
import moment from 'moment';
import BaseIcon from './components/BaseIcon';
import Autocomplete from 'v-autocomplete'
import Doughnut from './components/Doughnut';
import LineChart from './components/LineChart';
import BaseModal from './components/BaseModal';

import DefaultLayout from "./layouts/Default.vue";
import './assets/tailwind.css'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'

Vue.prototype.$http = axios;

import mixpanel from 'mixpanel-browser';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

Vue.component("default-layout", DefaultLayout);
Vue.component('doughnut', Doughnut);
Vue.component('LineChart', LineChart);
Vue.component('base-modal', BaseModal);
Vue.component('base-ison', BaseIcon);
console.log('environment');
console.log(process.env.NODE_ENV);

Vue.prototype.moment = moment;
Vue.prototype.mixpanel = mixpanel;

if (process.env.NODE_ENV === 'production') {
    Vue.prototype.$http.defaults.baseURL = `https://gem-finders-dot-gem-finder-team.uc.r.appspot.com/`;
    mixpanel.init('5433477c70ec37d074bc3ec512db0cc1', {debug: false}); 

}else {
    Vue.prototype.$http.defaults.baseURL = `http://localhost:3000`;
    console.log('loading dev mixpanel');
    mixpanel.init('d3079fd9d5e32022bc1ebe76728c89ed', {debug: true}); 
}

if (localStorage.getItem('access_token'))
  Vue.prototype.$http.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');

Vue.use(require('vue-moment'));
Vue.use(Autocomplete);

Vue.mixin({
  methods: {
    
    numberWithCommas(x) {
        if ( typeof x != "undefined" )
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else
            return "";
    },

    chunkArray(array, size) {
        let result = []
        for (var value of array){
            let lastArray = result[result.length -1 ]
            if(!lastArray || lastArray.length == size){
                result.push([value])
            } else{
                lastArray.push(value)
            }
        }
        return result
    }

  }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

export default {}