export const restorer = {
    namespaced: true,
    state: {
        show: false,
    },
    mutations: {
        setNotification(state, { resourceLabel, message, route, success, fail, action, fn, forceFn, data = {} }) {
            if (resourceLabel !== undefined && resourceLabel !== null) {
                state.message = resourceLabel + ' deleted successfully';
                state.successed = resourceLabel + ' restored successfully';
                state.failed = resourceLabel + ' cannot be restored';
            } else {
                state.message = message;
                state.successed = success;
                state.failed = fail;
            }

            state.show = true;
            state.data = data;
            state.route = route;
            state.fn = fn;
            state.action = action;

            state.forceFn = forceFn !== undefined && forceFn != null ? forceFn : false;

            setTimeout(() => {
                state.show = false;
                state.action = false;
                state.forceFn = false;
                state.fn = null;
                state.data = {};
            }, 6000);
        },
    },
    actions: {
        async set({commit}, data) {
            await commit('setNotification', data);
        },
    },
    modules: {
    },
    getters : {
    }
};