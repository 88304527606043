<template>
    <div class="min-h-screen">
        <link rel="stylesheet" href="https://use.typekit.net/zeb7bri.css">
        <div v-if="$route.name != 'login'" class="flex flex-wrap">
            <div class="w-full">
                <nav class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-light-purple">
                    <div class="px-4 mx-auto flex flex-wrap items-center">
                        <div class="">
                            <a class="text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-white" href="">
                                <img class="float-left mr-2"  width="20px" src="https://s2.coinmarketcap.com/static/cloud/img/loyalty-program/diamond-icon.svg">
                            </a>
                            <button class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button">
                                <span class="block relative w-6 h-px rounded-sm bg-white"></span>
                                <span class="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
                                <span class="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
                            </button>
                        </div>
                        <div class="ml-20" id="">
                            <ul class="flex flex-col lg:flex-row list-none ml-auto">
                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/home">
                                        Home
                                    </a>
                                    <div v-if="$route.name == 'home'" class="active-tab top-3 relative border-solid border"></div>
                                </li>
                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/gems">
                                        Gems
                                    </a>
                                    <div v-if="$route.name == 'gems'" class="active-tab top-3 relative border-solid border"></div>
                                </li>
                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/projects">
                                        Projects
                                    </a>
                                    <div v-if="$route.name == 'projects'" class="active-tab top-3 relative border-solid border"></div>
                                </li>
                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/news">
                                        News
                                    </a>
                                    <div v-if="$route.name == 'news'" class="active-tab top-3 relative border-solid border"></div>
                                </li>

                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/themes">
                                        Themes
                                    </a>
                                    <div v-if="$route.name == 'themes'" class="active-tab top-3 relative border-solid border"></div>
                                </li>

                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/features">
                                        Features
                                    </a>
                                    <div v-if="$route.name == 'features'" class="active-tab top-3 relative border-solid border"></div>
                                </li>

                                <!--li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/gems">
                                        Discover
                                    </a>
                                    <div v-if="$route.name == 'discover'" class="active-tab top-3 relative border-solid border"></div>
                                </li-->
                                <!--li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/governance">
                                        Wallet
                                    </a>
                                    <div v-if="$route.name == 'wallet'" class="active-tab top-3 relative border-solid border"></div>
                                </li -->

                                <li class="nav-item mr-4">
                                    <a class="px-3 py-2 flex items-center text-xs font-bold leading-snug text-white hover:opacity-75" href="/creepz">
                                        Creepz
                                    </a>
                                    <div v-if="$route.name == 'creepz'" class="active-tab top-3 relative border-solid border"></div>
                                </li>

                                
                            </ul>
                        </div>
                    </div>
                    <a v-on:click="logOut()">
                        <base-icon class="text-white mr-10" :name="'user'"></base-icon>
                    </a>

                </nav>
            </div>
        </div>

        <main class="default_layout">
            <slot/>
        </main>

        <footer class="ml-5 bottom-0">
            &copy; GEM FINDERS
        </footer>
    </div>
</template>

<script>
    import BaseIcon from '../components/BaseIcon';

    import { mapGetters } from 'vuex';

    export default {
        components: {
            BaseIcon
        },

        mounted( ) {
            if ( !this.isLoggedIn ) {
                this.$router.push('/').catch(()=>{});
            }
        },

        data() {
            return {
                sidebarOpen: false,
                showProfileDropdown: false,
                loading: null,
            }
        },

        beforeMount () {
            this.loading = this.$loading;
        },

        watch: {
            

            
        },

        computed: {
            ...mapGetters(['isLoggedIn'])
        },

        methods: {
            logOut: function() {
                localStorage.clear();
                window.location="/";

            }
        }
    }
</script>

<style>

    .standard-border {
        border: solid 1px rgb(233, 233, 233);
    }

    body {
    }

    .default_layout {
        padding-top: 0px !important;
    }

    .active-tab {
        border-color: #47E9FF;
        border-width: 2px;
        top: 15px;
    }

</style>
